import axios from "axios";
import { store } from "../store/configureStore";

export const UsersService = {
	get() {
		const state = store.getState();
		return axios.get("/api/policy/users",
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	},
	getById(id) {
		const state = store.getState();
		return axios.get(`/api/policy/users/${id}`,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	},
	save(data) {
		const state = store.getState();
		return axios.post(`/api/policy/users/save`,
			data,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	},
	delete(userId) {
		const state = store.getState();
		return axios.post(`/api/policy/users/delete/${userId}`,
			{},
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	}
}