import React, { useState } from "react";
import "antd/dist/antd.css";
import { Upload, Icon, message, Button } from 'antd';
import './ImageUpload.scss'


export default function ImageUpload(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [filePreview, setFilePreview] = useState('');

	const uploadButton = (
		<div className={props.overlayKey + ' image-upload-control'}>
			<div className="overlay">
				<Icon type={isLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">{props.label}</div>
			</div>
		</div>
	);

	const removeFile = (e) => {
		if (fileList[0]) {
			props.onFileRemoved(fileList[0]);
			setFilePreview('');
			setFileList([]);
		}
		
		e.stopPropagation();
	}

	const getBase64 = function (img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleChange = info => {
		if (!info.file.status) { //Intercepted file by beforeUpload don't have status field.
			return;
		}

		if (info.file.status === 'uploading') {
			setIsLoading(true);
		}

		if (info.file.status === 'done') {
			setIsLoading(false);
			props.onFileUpload({side: props.side, file: info.file.originFileObj, checkResult: info.file.response});
		}

		if (info.file.status === 'error') {
			setIsLoading(false);
			message.error('Failed to upload file');
		}
	

		let fl = [...info.fileList];
		fl = fl.slice(fl.length - 1);

		fl = fl.map(file => {
			if (file.response) {
				file.checkResult = file.response;
				file.originalFile = file.originFileObj;
				getBase64(file.originFileObj, imageUrl => {
					setFilePreview(imageUrl);
					props.onPreviewLoad(imageUrl);
				})
			}
			return file;
		});

		setFileList(fl);
	};

	const beforeUpload = function (fileToUpload) {
		setIsLoading(true);

		const isJpgOrPng = fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
			setIsLoading(false);
			return false;
		}
		const isLt5M = fileToUpload.size / 1024 / 1024 < 5;
		if (!isLt5M) {
			message.error('Image must smaller than 5MB!');
			setIsLoading(false);
			return false;
		}

		if (fileList.length > 0)
			props.onFileRemoved(fileList[0].originalFile);
	}

	return (
		<div>
			<Upload
				accept="image/png, image/jpeg"
				name="image"
				action="/api/checkImage"
				listType="picture-card"
				className="avatar-uploader"
				beforeUpload={beforeUpload}
				showUploadList={false}
				onChange={handleChange}
				fileList={fileList}
			>
				{fileList && fileList.length > 0 && filePreview ?
					<div className="file-preview">
						<img src={filePreview} alt="avatar" style={{ width: '100%' }} />
						<div className="overlay">
							<Button type="link" onClick={removeFile}>
								<Icon type='delete' /></Button>
						</div>
					</div> : uploadButton}
			</Upload>
			{fileList && fileList.length > 0 && fileList[0].checkResult &&
				<div className="image-status">
					{fileList[0].checkResult.applicable ?
						<div className="success"><Icon type="check" /> Applicable</div> :
						<div className="error"><Icon type="close" /> Not applicable</div>}
					{fileList[0].checkResult.gradable ?
						<div className="success"><Icon type="check" /> Gradable</div> :
						<div className="error"><Icon type="close" /> Not gradable</div>}
				</div>
			}
		</div>
	)
}