import React, { useEffect, useState } from "react";
import {
	useParams
} from "react-router-dom";
import { PresetsService } from "../../../services/presetsService";
import RolesFeatures from "../../../components/Presets/RoleFeatures"
import { Button, notification, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

function EditPresetPage(props) {
	const { getFieldDecorator } = props.form;
	let { id } = useParams();
	const [preset, setPreset] = useState(null);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isSavingInProgress, setSavingInProgress] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		Promise.all([PresetsService.getVisibleRoles(), PresetsService.getById(id)])
			.then(([roles, presetData]) => {
				setPreset(presetData);
				setRoles(roles);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [])

	const handleChange = (roleKey, items) => {
		var presetCopy = { ...preset }
		var roleFeatures = presetCopy.features[roleKey];

		roleFeatures.forEach(x => {
			x.enabled = items.some(i => i === x.attribute.attributeValue);
		});

		setPreset(presetCopy);
	};

	const editPreset = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors) {
				setSavingInProgress(true);

				PresetsService.save({
					name: values.name, id: preset.id, features: preset.features,
					hidden: preset.hidden
				})
					.then(() => notification['success']({ message: t('admin.presetEditedMessage') }))
					.catch(() => { notification.error({ message: t('admin.failedToEditPresetMessage') }); })
					.finally(() => { setSavingInProgress(false) });
			}
		});
	}

	return (
		<div>
			{
				!isLoading && preset ? <div>
					<h1 className="mb-2">
						{t('admin.editPreset')}
						<strong> {preset.name}
						</strong>
					</h1>
					<Form className="preset-form" onSubmit={editPreset}>
						<div>
							<Form.Item label={t('admin.presetName')}>
								{getFieldDecorator('name', {
									rules: [{ required: true, message: t('admin.presetNameInvalid') }],
									initialValue: preset.name,
								})(
									<Input />,
								)}
							</Form.Item>
						</div>
						<div className="preset-form">
							<div>
								{
									Object.keys(preset.features).map(role => {
										if (roles.some(r => r.name === role)) {
											const roleData = roles.filter(r => r.name === role)[0];

											return <RolesFeatures key={role} role={roleData}
												isReadonly={props.isReadonly}
												handleChange={handleChange}
												items={preset.features[role].map(x => x.attribute)}
												selectedItems={preset.features[role].filter(x => x.enabled).map(x => x.attribute.attributeValue)}></RolesFeatures>
										}

										return null;
									})
								}
							</div>
							<div>
								<Button
									loading={isSavingInProgress}
									type="primary" onClick={editPreset}
									disabled={props.isReadonly}>
										{t('common.save') }
									</Button>
							</div>
						</div>
					</Form>
				</div> : <div>Preset with id {id} not found</div>
			}
		</div>
	);
}

export default Form.create()(EditPresetPage);