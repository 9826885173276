import React from "react";
import { Select, Icon } from 'antd';
import './LanguageSwitch.scss'

export default function LanguageSwitch(props) {
    const { Option } = Select;

    return (
        <div className="language-select-container">
            <Icon type="global" />
            <Select defaultValue={props.languages.filter(x => x.default)[0].code} className="language-select"
                onChange={props.onLanguageSwitch}>
                {props.languages.map(x => (
                    <Option key={x.code} value={x.code}>
                        {x.code}
                    </Option>
                ))}
            </Select>
        </div>);
}