import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./WorklistPage.scss";
import { useHistory } from 'react-router-dom';
import { ExamApiService } from "../../api/ExamApi.service";
import { useSelector, useDispatch } from 'react-redux'
import { update } from "../../reducers/paginationSlice";
import { updateSort } from "../../reducers/sortSlice";
import Grid from "../../components/Grid/Grid";
import { Tag, Button, Divider } from 'antd';
import Moment from 'react-moment';
import { Diagnosis } from '../../components/Diagnosis/Diagnosis';
import GuardedRenderer from "../../components/GuardedRenderer/GuardedRenderer";
import { useTranslation } from "react-i18next";

export default function WorklistPage() {
	const {t} = useTranslation();
	const pagination = useSelector((x) => x.worklist.pagination['SPAIN_GRID_FEATURE']);
	const sort = useSelector((x) => x.worklist.sort['SPAIN_GRID_FEATURE']);
	const [examResendPending, setExamResendPending] = useState(false);
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false);
	const [exams, setExams] = useState([]);
	const [searchParams] = useState('');
	let history = useHistory();

	const examStates = {
		'ANALYZED': {
			title: t('list.new'),
			color: 'green'
		}, 
		'ANALYSING': {
			title: t('list.pending'),
			color: 'orange'
		},
		'FAILED': {
			title: t('list.failed'),
			color: 'red'
		},
		'PROCESSED': {
			title: t('list.processed')
		}
	};

	const columns = [
		{
			title: t('list.status'),
			dataIndex: 'examinationStatus',
			key: 'examinationStatus',
			sorter: true,
			sortOrder: undefined,
			render: status => {
				return examStates[status] ? <Tag color={examStates[status].color}>
					{examStates[status].title.toUpperCase()}
				</Tag> : <Tag>
					{status}
				</Tag>
			},
			width: 150
		},
		{
			title: t('list.examDate'),
			dataIndex: 'examinationDate',
			sorter: true,
			sortOrder: undefined,
			render: date => (
				<Moment format="DD-MM-yyyy HH:mm">
					{date}
				</Moment>
			),
			width: 200
		},
		{
			title: t('list.patientNumber'),
			dataIndex: 'patientNumber',
			width: 150
		},
		{
			title: t('list.author'),
			render: (a, record) => {
				return <div>
					{record.creatorUserInfo && record.creatorUserInfo.userName}
				</div>
			},
			width: 150
		},
		{
			title: t('common.department'),
			render: (a, record) => {
				return <div>
					{record.creatorDepartment && record.creatorDepartment.name}
				</div>
			},
			width: 200
		},
		{
			title: t('list.diagnosis'),
			dataIndex: 'diagnosisleft',
			render: (a, record) => {
				return <div>
					<div>
						<Diagnosis title={t('common.OS')} diagnosisSet={record.leftEyeDiagnosis} />
					</div>
					<div>
						<Diagnosis title={t('common.OD')} diagnosisSet={record.rightEyeDiagnosis} />
					</div>
				</div>
			},
			width: 200
		},
		{
			title: t('list.actions'),
			render: (a, record) => renderActionsColumn(a, record),
			width: 200
		}
	];

	if (sort) {
		const sortedColumn = columns.filter(x => x.dataIndex === sort.column)[0];

		if (sortedColumn) {
			sortedColumn.sortOrder = sort.order;
		}
	}

	useEffect(() => {
		loadExams(
			pagination.current,
			sort.column,
			sort.order,
			searchParams);
	}, []);

	const renderActionsColumn = function(a, record) {
		return (<div>
			<a onClick={(e) => handleDownloadClick(e)}
				href={`/api/examinations/${record.examinationId}/imagesZip`}
				download={`images${record.examinationId}.zip`}
				title={t('list.downloadExplanation')}>
					{t('common.download')}
				</a>
				{
					<GuardedRenderer requiredFeature="RESEND_EXAM">
						{record.examinationStatus === 'FAILED' && 
							<>
							<Divider type="vertical" />
							<Button onClick={(e) => handleResendClick(e, record)} 
									className="p-0"	type='link' loading={examResendPending[record.examinationId]}>
									{t('list.resend')}
							</Button>
						</>}
					</GuardedRenderer>
				}
		</div>)
	}

	const handleResendClick = function(e, record) {
		setExamResendPending({[record.examinationId] : true});
		ExamApiService.resendExam(record.examinationId)
			.then(() => {
				loadExams(
					pagination.current,
					sort.column,
					sort.order,
					searchParams);
			})
			.finally(() => {
				setExamResendPending({[record.examinationId] : false});
			});

		e.stopPropagation();
		return false;
	}

	const handleDownloadClick = (e) => {
		e.stopPropagation();

		return false;
	}


	const loadExams = function (page, sortField, sortOrder, searchParams) {
		setIsLoading(true);

		if (sortOrder === undefined || sortField === undefined) {
			sortField = undefined;
			sortOrder = undefined;
		}

		return ExamApiService
			.getPaginatedExaminations(page, pagination.pageSize, sortField, sortOrder, searchParams)
			.then(
				exams => {
					setExams(exams.data);
					setIsLoading(false);
					
					dispatch(update({
						SPAIN_GRID_FEATURE: {
							current: page,
							pageSize: pagination.pageSize,
							total: exams.totalElements,
							position: 'both'
						}
					}));

					dispatch(updateSort({SPAIN_GRID_FEATURE: {
						column: sortField,
						order: sortOrder
					}}));
				},
				() => {
					setIsLoading(false);
				}
			);
	}

	const handleTableChange = (pagination, filters, sorter) => {
		loadExams(pagination.current, sorter.columnKey, sorter.order, searchParams)
	};

	const onRowClick = function(record) {
		history.push(`./viewer/${record.examinationId}`);
	}

	return (
		<Grid columns={columns} handleTableChange={handleTableChange} isLoading={isLoading}
			data={exams} pagination={pagination} onRowClick={onRowClick}></Grid>
	);
}