import React, { useState, useEffect } from "react";
import { Form, Select, notification, Layout, Input, Button } from 'antd';
import { DepartmentsService } from "../../../services/departmentsService";
import { OrganizationsService } from "../../../services/organizationsService";
import {
	useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreateDepartmentPage(props) {
	const { getFieldDecorator } = props.form;
	const [organizations, setOrganizations] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isSavingInProgress, setIsSavingInProgress] = useState(false);
	const [organization, setOrganization] = useState(null);
	const {t} = useTranslation();
	let history = useHistory();

	useEffect(() => {
		OrganizationsService.getAll().then((org) => {
			setOrganizations(org);
		})
		.finally(() => setLoading(false));

	}, [])

	const createDepartment = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors && organization) {
				setIsSavingInProgress(true);

				DepartmentsService.save({
					name: values.name,
					description: values.description, 
					organizationId: organization
				})
				.then(() => {
					history.push('/dashboard/admin/departments');
					notification['success']({ message: t('admin.departmentCreatedMessage') });
				})
				.catch(() => {notification['warning']({ message: t('admin.failedToCreateDepartment')})})
				.finally(() => {
					setIsSavingInProgress(false);
				});
			}
		});
	}

	return (
		<Layout>
			{isLoading ? <div>Loading...</div> : <div>
				<h1>
					{t('admin.createDepartment')}
				</h1>
				<Form className="create-edit-form" onSubmit={createDepartment}>
					<div>
						<Form.Item label={t('admin.departmentName')}>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('admin.departmentNameInvalid') }],
							})(
								<Input />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.departmentDescription') }>
							{getFieldDecorator('description', {
								rules: [{ required: true, message: t('admin.departmentDescriptionInvalid') }],
							})(
								<Input />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('common.organization')}>
							<Select
								value={organization}
								onChange={(x) => setOrganization(x)}
								style={{ width: 500 }}
							>
								{organizations.map(item => (
									<Select.Option key={item.id} value={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div className="mt-2">
						<Button htmlType="submit" loading={isSavingInProgress}
							type="primary">{t('common.save')}</Button>
					</div>
				</Form>
			</div>}
		</Layout>
	);
}

export default Form.create()(CreateDepartmentPage);