import React from "react";
import Moment from 'react-moment';


export function PatientName(props) {
	const { exam } = props;

	return (
		<div className="patient-name-container">
			<span style={{marginRight: "1rem"}}>
				{exam.patientName}
			</span>
			<span style={{marginRight: "1rem"}}>
				#{exam.patientNumber}
			</span>
			{
				exam.patientBirthDate &&
				<span>
					<Moment format="DD-MM-yyyy">
						{exam.patientBirthDate}
					</Moment> (<Moment className="years" fromNow ago>{exam.patientBirthDate}</Moment>)
				</span>
			}
		</div>
	)
}
