import React from "react";
import "./ManualIcon.css";
import "antd/dist/antd.css"
import { useTranslation } from "react-i18next";

export default function ManualIcon() {
    const { t } = useTranslation();

    return (
        <a className={"manualIcon"}
        href="/resources/manual"
        target="_blank">
         <p className={"text"}>
            {t('common.userManual')}
         </p>
     </a>
    )
}
