import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UsersService } from "../../../services/usersService";
import { PresetsService } from "../../../services/presetsService";
import { OrganizationsService } from "../../../services/organizationsService";
import GuardedRenderer from "../../../components/GuardedRenderer/GuardedRenderer";

export function UsersPage() {
	let { url } = useRouteMatch();
	let history = useHistory();
	const {t} = useTranslation();

	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		Promise.all([UsersService.get(), PresetsService.getVisibleRoles(), OrganizationsService.getAll()])
			.then(([users, roles, organizations]) => {

				const mappedUsers = users.map(user => {
					var role = roles.find(role => role.id === user.roleId);
					user.roleName = role ? role.name : '';

					if (organizations.length === 0 || user.organizations.length === 0)
						return user;

					var organizationNames = user.organizations.reduce((acc, userOrganization) => {
						var orgData = organizations.find(organization => organization.id === userOrganization.organizationId);
						
						if (acc) {
							return orgData ? acc += `, ${orgData.name}` : acc;
						}

						return orgData ? orgData.name : '';

					}, '');

					user.organizationNames = organizationNames;

					return user;
				});

				var odrederData = mappedUsers.sort((a, b) => {
					return b.userId - a.userId;
				});

				setUsers(odrederData);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const columns = [
		{
			title: t('common.username'),
			dataIndex: 'userName',
			key: 'name',
			width: 150,
			sorter: (a, b) => {
				if (!a.userName)
					return -1;
				if (!b.userName)
					return 1;
					
				return a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1;
			}
		},
		{
			title:  t('common.role'),
			dataIndex: 'roleName',
			key: 'role',
			width: 150,
		},
		{
			title:  t('common.organization'),
			dataIndex: 'organizationNames',
			key: 'organizationNames',
			width: 150,
		},
	];

	return (
		<div>
			<div className="d-flex justify-content-between">
				<h1>
					{t('common.users')}
				</h1>
				<GuardedRenderer requiredFeature="ALLOW_CHANGE_ORGANIZATIONS">
					<Link to={`${url}/create`}>
						{t('admin.createUser')}
					</Link>
				</GuardedRenderer>
			</div>
			<div>
				<Table
					loading={isLoading}
					pagination={false}
					dataSource={users}
					columns={columns}
					scroll={{ y: '600px', x: false }}
					rowKey={(r) => r.userId}
					onRow={(record) => {
						return {
							onClick: () => {
								history.push(`users/${record.userId}`);
							}
						}
					}}
				/>
			</div>
		</div>
	);
}