import {
	SET_USER_CURRENT_ORGANIZATION,
	SET_USER_FEATURES,
	SET_USER_ORGANIZATIONS,
	CLEAN_USER_SETUP, 
	SET_LOGED_IT_USER
} from "../actions/accountActions";

const initialState = {
	features: [],
	organization: null,
	organizations: [], 
	isLogedIn: false
};

export function accountReducer(state = initialState, action) {
	switch (action.type) {
		case SET_USER_FEATURES:
			return {
				...state,
				features: action.features,
			};
			case SET_LOGED_IT_USER:
				return {
					...state,
					isLogedIn: action.isLogedIn,
				};
		case SET_USER_CURRENT_ORGANIZATION:
			return {
				...state,
				organization: action.organization,
			};
		case SET_USER_ORGANIZATIONS:
			return {
				...state,
				organizations: action.organizations,
			};
		case CLEAN_USER_SETUP:
			return {
				...state,
				organizations: [],
				organization: null,
				features: [],
				isLogedIn: false
			};

		default:
			return state;
	}
}