import React, { useState, useEffect } from "react";
import { Table, Select } from 'antd';
import {
	Link,
	useRouteMatch,
	useHistory
} from "react-router-dom";

import { DepartmentsService } from "../../../services/departmentsService";
import { OrganizationsService } from "../../../services/organizationsService";
import GuardedRenderer from "../../../components/GuardedRenderer/GuardedRenderer";
import { useTranslation } from "react-i18next";

export function DepartmentsPage() {
	const { Option } = Select;
	let history = useHistory();
	let { url } = useRouteMatch();
	const [departments, setDepartments] = useState([]);
	const [filteredDepartments, setFliteredDepartments] = useState([]);
	const [loading, setLoading] = useState(true);
	const [organizations, setOrganizations] = useState([]);
	const {t} = useTranslation();

	useEffect(() => {
		OrganizationsService.getAll()
			.then((data) => {
				if (data) {
					setOrganizations(data);

					var requestsArray = data.map((org) => {
						return DepartmentsService.getAll(org.id);
					});

					Promise.all(requestsArray)
						.then((deps) => {
							var allDepartments = [];

							deps.forEach((d) => {
								d.forEach((x) => {
									var linkedOrganization = data.filter((o) => {
										return o.id === x.organizationId ? o : null;
									})[0];

									x.organizationName = linkedOrganization ? linkedOrganization.name : '';

									allDepartments.push(x);
								});
							});

							var odrederData = allDepartments.sort((a, b) => {
								return b.id - a.id;
							});

							setDepartments(odrederData);
							setFliteredDepartments(odrederData);
						})
						.finally(() => setLoading(false));
				}
			});
	}, [])

	const handleChange = (value) => {
		if (value) {
			var d = departments.filter((x) => x.organizationId === value);

			setFliteredDepartments(d);
		}
		else {
			setFliteredDepartments(departments);
		}
	}

	const columns = [
		{
			title: t('common.name'),
			dataIndex: 'name',
			key: 'name',
			width: 300,
			sorter: (a, b) => {
				if (!a.name)
					return -1;
				if (!b.name)
					return 1;
					
				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
			}
		},
		{
			title: t('common.description'),
			dataIndex: 'description',
			key: 'description',
			width: 300,
		},
		{
			title: t('common.organization'),
			dataIndex: 'organizationName',
			key: 'organizationName',
			width: 300
		}
	];

	return (
		<div>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h1 className="mb-0">
					{ t('common.departments')}
				</h1>
				<GuardedRenderer requiredFeature="ALLOW_CHANGE_ORGANIZATIONS">
					<Link to={`${url}/create`}>{ t('admin.createDepartment')}</Link>
				</GuardedRenderer>
			</div>
			<div className="mt-2 mb-2 d-flex flex-column">
				<label className="mb-1">{t('common.organization')}</label>
				<Select defaultValue="" style={{ width: 200 }} onChange={handleChange} allowClear={true}>
					<Option key="all" value="">{t('admin.allOrganizations')}</Option>
					{organizations.map((o) => {
						return <Option key={o.id} value={o.id}>{o.name}</Option>
					})}
				</Select>
			</div>

			<Table
				loading={loading}
				pagination={false}
				dataSource={filteredDepartments}
				scroll={{ y: '600px', x: false }}
				columns={columns}
				rowKey={(r) => r.id}
				onRow={(record) => {
					return {
						onClick: () => {
							history.push(`departments/${record.id}`);
						}
					}
				}} />
		</div>
	);
}