import React, { useEffect } from "react";
import { Layout, Checkbox, Button, Tooltip, Input, Icon } from "antd";
import "./Menu.scss";
import GuardedRenderer from "../../../../components/GuardedRenderer/GuardedRenderer";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;
const { Sider } = Layout;

export function Menu(props) {
	const {t} = useTranslation();

	useEffect(
		() => {
			if (!props.selectedImageEyeSide) {
				props.selectImage(props.eyeImages.filter(x => !x.loadFailed && x.eyeSide === 'right')[0]
					|| props.eyeImages.filter(x => !x.loadFailed && x.eyeSide === 'left')[0]);
			} else {
				props.selectImage(props.eyeImages.filter(x => !x.loadFailed &&
					x.eyeSide === props.selectedImageEyeSide)[0] || props.eyeImages.filter(x => !x.loadFailed)[0]);
			}
		},
		[props.selectedImageEyeSide]
	)

	const renderFailedImage = (image) => {
		return (
			<Tooltip placement="right" title="No image" key={image.id}>
				<div className="image-data">
					<div className={`eye-image disabled`}>
						<Icon type="exception" />
						<div className="diagnosis">
							{image.hasRetinopathy && <span>DR</span>}
							{image.hasAmd && <span>AMD</span>}
							{image.hasGlaucoma && <span>G</span>}
							{image.gradable === false && <span>UNGR</span>}
						</div>
					</div>
				</div>
			</Tooltip>
		);
	}

	const renderImage = (image, isSelected, selectImageCallback) => {
		if (image.imageBlob) {
			var imageUrl = URL.createObjectURL(image.imageBlob);

			if (image.loadFailed)
				return renderFailedImage(image);

			return (
				<div className="image-data" key={image.id}>
					<div className={`eye-image ${isSelected && "selected"}`} onClick={() => selectImageCallback(image)}>
						<img src={imageUrl} alt="eye" />
						<div className="diagnosis">
							{image.hasRetinopathy && <span>{t('common.diagnosis.DR')}</span>}
							{image.hasAmd && <span>{t('common.diagnosis.AMD')}</span>}
							{image.hasGlaucoma && <span>{t('common.diagnosis.G')}</span>}
						</div>
					</div>
					{
						image.gradable === false &&
						<div className="image-status">
							<div className="error"><Icon type="close" />{t('viewer.notGradable')}</div>
						</div>
					}
				</div>
			);
		}
	}

	const areDiagnosisControlsDisabled = (exam) => {
		return exam.examinationStatus === 'ANALYSING' || (!exam.rightEyeDiagnosis && !exam.leftEyeDiagnosis);
	}

	const changeEyeDiagnosis = (type, data) => {
		props.setExaminationStatus(type, data);
	}

	const changeNote = (e) => {
		props.setDoctorNote(e.target.value);
	}

	const renderUnavailableOverallResult = () => {
		return (
			<div>
				<p>Diagnosis data are not available now</p>
			</div>
		);
	}

	const renderOverallResult = (eyeDiagnosis, type, isDisabled) => {
		return (
			<div className="result" key={type}>
				<h3>{t('viewer.overallResult')}</h3>
				<Tooltip placement="right" title={t('common.diagnosis.DiabeticRetinopathy')}>
					<Checkbox checked={eyeDiagnosis && eyeDiagnosis.hasRetina} disabled={isDisabled}
						onChange={() => changeEyeDiagnosis(type, { ...eyeDiagnosis, hasRetina: !eyeDiagnosis.hasRetina })}>
						{t('common.diagnosis.DR')}
					</Checkbox>
				</Tooltip>
				<Tooltip placement="right" title={t('common.diagnosis.SignsOfAMD')}>
					<Checkbox checked={eyeDiagnosis && eyeDiagnosis.hasAmd} disabled={isDisabled}
						onChange={() => changeEyeDiagnosis(type, { ...eyeDiagnosis, hasAmd: !eyeDiagnosis.hasAmd })}>
						{t('common.diagnosis.AMD')}</Checkbox>
				</Tooltip>
				<Tooltip placement="right" title={t('common.diagnosis.SignsOfGlaucoma')}>
					<Checkbox checked={eyeDiagnosis && eyeDiagnosis.hasGlaucoma} disabled={isDisabled}
						onChange={() => changeEyeDiagnosis(type, { ...eyeDiagnosis, hasGlaucoma: !eyeDiagnosis.hasGlaucoma })}>
						{t('common.diagnosis.G')}</Checkbox>
				</Tooltip>
				<Tooltip placement="right" title={t('common.diagnosis.CRVO')}>
					<Checkbox checked={eyeDiagnosis && eyeDiagnosis.hasCrvo} disabled={isDisabled}
						onChange={() => changeEyeDiagnosis(type, { ...eyeDiagnosis, hasCrvo: !eyeDiagnosis.hasCrvo })}>
						{t('common.diagnosis.CRVO')}</Checkbox>
				</Tooltip>
				<Tooltip placement="right" title={t('common.diagnosis.BRVO')}>
					<Checkbox checked={eyeDiagnosis && eyeDiagnosis.hasBrvo} disabled={isDisabled}
						onChange={() => changeEyeDiagnosis(type, { ...eyeDiagnosis, hasBrvo: !eyeDiagnosis.hasBrvo })}>
						{t('common.diagnosis.BRVO')}</Checkbox>
				</Tooltip>
			</div>
		);
	}

	const renderReadonlyDoctorResult = () => {
		return (<>
			<div className="overall-result">
				{
					[(props.eyeImages && props.eyeImages.filter(image => image.eyeSide === 'right').length > 0 &&
						renderOverallResult(props.exam.rightEyeDiagnosis, 'right', true)),
					(props.eyeImages && props.eyeImages.filter(image => image.eyeSide === 'left').length > 0 &&
						renderOverallResult(props.exam.leftEyeDiagnosis, 'left', true))
					]
				}
			</div>

			<div className="doctor-notes">
				<h3>{t('viewer.notes')}</h3>
				<TextArea
					disabled={true}
					value={props.exam.doctorNote}
					onChange={changeNote}
					maxLength="1000"
					autoSize={{ minRows: 5, maxRows: 15 }}
				/>
			</div>
		</>);
	}

	return (
		<Sider className="images-menu" width="350px">
			<div className="eye-data-container">
				{
					props.eyeImages.filter(image => image.eyeSide === 'right').length > 0 && <div className="eye-data">
						<h2>
							{t('common.OD')}
						</h2>
						{props.eyeImages.filter(image => image.eyeSide === 'right')
							.map(image => renderImage(image, image === props.selectedImage, props.selectImage))}
					</div>
				}
				{props.eyeImages.filter(image => image.eyeSide === 'left').length > 0 && <div className="eye-data">
					<h2>{t('common.OS')}</h2>
					{props.eyeImages.filter(image => image.eyeSide === 'left')
						.map(image => renderImage(image, image === props.selectedImage, props.selectImage))}
				</div>}
			</div>
			{props.exam && <div className="exam-extra-info">
				{props.exam.isEyeDropUsed && <span>{t('viewer.eyeDrops')}</span>}
				{props.exam.isNoContradiction && <span>{t('viewer.contraindication')}</span>}
				{props.exam.isPatientDiabetic && <span>{t('viewer.diabetic')}</span>}
			</div>}
			<GuardedRenderer requiredFeature="PROCESS_EXAM" fallbackComponent=
				{props.exam && renderReadonlyDoctorResult()}>
				{props.exam &&
					<>
						<div className="overall-result">
							{(areDiagnosisControlsDisabled(props.exam))
								? renderUnavailableOverallResult()
								: [(props.eyeImages && props.eyeImages.filter(image => image.eyeSide === 'right').length > 0 &&
									renderOverallResult(props.exam.rightEyeDiagnosis, 'right')),
								(props.eyeImages && props.eyeImages.filter(image => image.eyeSide === 'left').length > 0 &&
									renderOverallResult(props.exam.leftEyeDiagnosis, 'left'))
								]
							}
						</div>
						{!areDiagnosisControlsDisabled(props.exam) &&
							<>
								<div className="doctor-notes">
									<h3> {t('viewer.notes')}</h3>
									<TextArea
										disabled={areDiagnosisControlsDisabled(props.exam)}
										value={props.exam.doctorNote}
										onChange={changeNote}
										maxLength="1000"
										autoSize={{ minRows: 5, maxRows: 15 }}
									/>
								</div>
								<div className="upload-button-container">
									<Button disabled={areDiagnosisControlsDisabled(props.exam)}
										className="upload-button" size="small" type="danger" onClick={props.saveExams}>
											{t('viewer.process')}
									</Button>
								</div>
							</>
						}
					</>
				}
			</GuardedRenderer>
		</Sider>
	)
}