import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, Layout, message, Select } from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import "./UploadPage.scss";
import { UserApiService } from '../../api/UserApi.service.';
import { ExamApiService } from '../../api/ExamApi.service';
import ImageViewer from '../viewer/components/ImageViewer/ImageViewer';
import {UserFeaturesService} from '../../services/userFeaturesEnum';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const { Option } = Select;

function AddExamPage(props) {
	const {t} = useTranslation();
	const features = useSelector((x) => x.account.features);
	const [selectedImage, setSelectedImage] = useState(null);
	const [userDepartments, setUserDepartments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [referenceId, setReferenceId] = useState('');
	const [department, setDepartmentId] = useState(null);
	const [isPatientDiabetic, setIsPatientDiabetic] = useState(false);
	const [isNoContradiction, setIsNoContradiction] = useState(false);
	const [forceToSendNotGradable, setForceToSendNotGradable] = useState(false);
	const [isEyeDropUsed, setIsEyeDropUsed] = useState(false);
	const [formSubmited, setFormSubmited] = useState(false);

	const hasInvalidImageOrNoImages = () => {
		return (files.length === 0) || (files.length > 0 && files.some(x => x.checkResult !== null &&
			(!x.checkResult.applicable)));
	}

	const hasNotGradableImage = () => {
		return files.length > 0 && files.some(x => x.checkResult !== null && !x.checkResult.gradable);
	}

	const setImageToViewer = (file) => {
		var img = {};
		img.domImage = new Image();
		img.domImage.src = file;
		img.domImage.onload = () => {
			setSelectedImage(img);
		}
	}

	const onSelect = (val) => {
		setFormSubmited(false);
		setDepartmentId(val);
	}

	const onFileUpload = (file) => {
		setFormSubmited(false);
		let newArray = [...files];
		newArray.push(file);
		setFiles(newArray);

		setSelectedImage(file.file);
	}

	const onFileRemoved = (file) => {
		setFormSubmited(false);
		setFiles(files.filter(x => x.file.uid !== file.uid));
	}

	useEffect(() => {
		UserApiService.getDepartments().then(
			(x) => {
				setUserDepartments(x);
				setDepartmentId(x.length !== 0 ? x[0].id : null);
			});
	}, [features])


	const onReferenceChange = (e) => {
		setFormSubmited(false);
		setReferenceId(e.target.value);
	}

	const onSubmit = () => {
		setFormSubmited(true);

		if (hasNotGradableImage() && !forceToSendNotGradable) {
			return;
		}

		setIsLoading(true);

		let leftEyeImages = files.filter(x => x.side === 'left').map(x => x.file);
		let rightEyeImages = files.filter(x => x.side === 'right').map(x => x.file);

		let exam = {
			patientNumber: referenceId, departmentId: department,
			isPatientDiabetic, isNoContradiction, isEyeDropUsed,
			examinationDate: new Date()
		};

		ExamApiService.createExam(exam, leftEyeImages, rightEyeImages)
			.then(
			() => {
				message.success(t('upload.examCreated'));
				var redirect = UserFeaturesService.getExamsListRouteOrDefault(features);
				if (redirect) {
					props.history.push(redirect.route);
				}
			})
			.catch(() => {
				message.error(t('upload.examCreationFailed'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Layout className="upload-page">
			<div className="form upload-form">
				<h1>
					{t('common.upload')}
				</h1>

				<div className="image-uploads">
					<div>
						<h2>{t('common.OD')}</h2>
						<div className="eye-upload">
							<ImageUpload onPreviewLoad={setImageToViewer}
								onFileRemoved={onFileRemoved} onFileUpload={onFileUpload}
								overlayKey="od-m"
								label={t('upload.imageLeftEyeMacula')} side="right" />
							<ImageUpload onPreviewLoad={setImageToViewer}
								onFileRemoved={onFileRemoved} onFileUpload={onFileUpload}
								overlayKey="od-d"
								label={t('upload.imageLeftEyeDisc')} side="right" />
						</div>
					</div>
					<div>
						<h2>{t('common.OS')}</h2>
						<div className="eye-upload">
							<ImageUpload onPreviewLoad={setImageToViewer}
								onFileRemoved={onFileRemoved} onFileUpload={onFileUpload}
								overlayKey="os-m"
								label={t('upload.imageRightEyeMacula')} side="left" />
							<ImageUpload onPreviewLoad={setImageToViewer}
								onFileRemoved={onFileRemoved} onFileUpload={onFileUpload}
								overlayKey="os-d"
								label={t('upload.imageRightEyeDisc')} side="left" />
						</div>
					</div>
				</div>

				{userDepartments.length > 0 && <div className="form-block">
					<label>{t('common.department')}</label>
					<Select value={department} onChange={onSelect}>
						{userDepartments.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
					</Select>
				</div>}
				<div className="form-block">
					<label>{t('upload.reference')}</label>
					<Input value={referenceId} onChange={onReferenceChange} />
				</div>
				<div className="form-block">
					<Checkbox onChange={(e) => setIsPatientDiabetic(e.target.checked)}>
						{t('viewer.diabetic')}
					</Checkbox>
					<Checkbox onChange={(e) => setIsNoContradiction(e.target.checked)}>
						{t('viewer.contraindication')}
					</Checkbox>
					<Checkbox onChange={(e) => setIsEyeDropUsed(e.target.checked)}>{t('viewer.eyeDrops')}</Checkbox>
				</div>

				{
					hasNotGradableImage() && formSubmited && <div className="form-block">
						<Checkbox onChange={(e) => setForceToSendNotGradable(e.target.checked)}>
							{t('upload.imagesGradibilityWarning')}
						</Checkbox>
					</div>
				}

				<Button type="danger" disabled={isLoading || hasInvalidImageOrNoImages() || !referenceId}
					loading={isLoading} onClick={onSubmit}>
					{t('common.create')}
				</Button>
			</div>
			<div className="viewer">
				{selectedImage && selectedImage.domImage && <ImageViewer hideMagnifier={true} image={selectedImage} />}
			</div>
		</Layout>
	)
}

export default withRouter(AddExamPage);
