import { useSelector } from 'react-redux';


export default function GuardedRenderer(props) {
	const userFeatures = useSelector((x) => x.account.features);

	if (!props.requiredFeature) {
		return props.children;
	}

	if (!userFeatures) {
		return null;
	}

	if (userFeatures.some(f => f.attributeValue === props.requiredFeature) &&
		userFeatures.every(f => f.attributeValue !== props.excludedFeature)) {
		if (props.children) {
			return props.children;
		}
	}

	return props.fallbackComponent || null;
}