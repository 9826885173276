import React from "react";
import { Select, Checkbox, Button, Icon } from 'antd';
import { useTranslation } from "react-i18next";

import './OrganizationSelect.scss';

export default function OrganizationSelect(props) {
	const {t} = useTranslation();

	return (
		<div className="organization-select">
			<div className="mb-1">
				<label>
					{t('common.organization')}
				</label>
				<Select
					value={props.selectedOrganization.id}
					disabled={props.isReadonly}
					onChange={(x) => props.selectOrganization(x, props.selectedOrganization.id)}
					style={{ width: '100%' }}
				>
					{props.organizations.map(item => (
						<Select.Option key={item.id} value={item.id}>
							{item.name}
						</Select.Option>
					))}
					{props.selectedOrganization.id &&
						<Select.Option key={props.selectedOrganization.id} value={props.selectedOrganization.id}>
							{props.selectedOrganization.name}
						</Select.Option>
					}
				</Select>
			</div>

			<div className="mb-1">
				<label>
					{t('common.department')}
				</label>
				<Select
					disabled={props.selectedOrganization.allDepartments || props.isReadonly}
					mode="multiple"
					value={props.selectedDepartments}
					onChange={(x) => props.selectDepartment(x, props.selectedOrganization.id)}
					style={{ width: '100%' }}
				>
					{props.selectedOrganization && props.departments &&
						props.departments.filter(d => d.organizationId === props.selectedOrganization.id).map(item => (
							<Select.Option key={item.id} value={item.id}>
								{item.name}
							</Select.Option>
						))}
				</Select>
			</div>
			<div>
				<Checkbox disabled={props.isReadonly} onChange={(x) => props.onAllDepartmentsCheck(x, props.selectedOrganization.id)}
					checked={props.selectedOrganization.allDepartments}>
						{t('admin.allDepartments')}
					</Checkbox>
			</div>
			<div className="remove-control">
				<Button type="link" disabled={props.isReadonly} onClick={() => props.onOrganizationRemove(props.selectedOrganization.id)}>
					<Icon type="close" />
				</Button>
			</div>
		</div>)
}