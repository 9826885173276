export const OcuscreenFilters = {
	grayscaleImageDataByLumaFormula: (imageData) => {
		const data = imageData.data;
		let color;
		for (let i = 0; i < data.length; i += 4) {
			color = data[i] * 0.21 + data[i + 1] * 0.72 + data[i + 2] * 0.07;
			data[i] = data[i + 1] = data[i + 2] = color;
		}
	
		return imageData;
	},
	
	grayscaleImageData: (imageData) => {
		const data = imageData.data;
		for (let i = 0; i < data.length; i += 4) {
			data[i] = data[i + 1];
			data[i + 2] = data[i + 1];
		}
	
		return imageData;
	}
}
