import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { SupportApiService } from "../../api/SupportApi.service";
import { Table, Button, Checkbox, Modal, message } from 'antd';
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
import './FeedbackPage.scss';

export default function FeedbackPage() {
	const {t} = useTranslation();
	const [feedbackList, setFeedbacklList] = useState([]);
	const [imageUrl, setImageUrl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const features = useSelector((x) => x.account.features);

	useEffect(() => {
		SupportApiService.getFeedbackList()
			.then((data) => {
				setFeedbacklList(data);
			})
			.catch(() => {
				message.error(t('feedback.failedToLoadFeedbacksList'))});
	}, [features])

	const openModalWithAttachment = (feedbackId) => {
		SupportApiService.getAttachmentUrl(feedbackId)
		.then(url => {
			setImageUrl(url);
			setModalOpen(true);
		})
		.catch(() => {
			message.error(t('feedback.failedToLoadFeedbackAttachment'))}
			);
	}

	const onModalClose = () => {
		setImageUrl(null);
		setModalOpen(false);
	}

	const onChange = (e, feedbackId) => {
		SupportApiService.processFeedback(feedbackId)
			.then(() => {
				setFeedbacklList(feedbackList.map((f) => {
					if (f.id === feedbackId) {
						f.processed = e.target.checked;
					}

					return f;
				}));

				message.success(t('feedback.feedbackWasProcessed'));
			})
			.catch(() => {message.error(t('feedback.failedToProcessFeedback'))});
	}

	const columns = [
		{
			title: t('feedback.status'),
			dataIndex: 'processed',
			key: 'processed',
			render: (processed, record) => {
				return <Checkbox checked={processed} disabled={processed} onChange={(e) => onChange(e, record.id)}></Checkbox>
			},
			width: 100
		},
		{
			title: t('common.user'),
			dataIndex: 'user',
			key: 'user',
			render: user => <span>{user && user.userName}</span>,
		},
		{
			title: t('common.email'),
			dataIndex: 'userEmail',
			key: 'userEmail',
			render: text => <span>{text}</span>,
		},
		{
			title: t('feedback.message'),
			dataIndex: 'message',
		},
		{
			title: t('feedback.creationDate'),
			dataIndex: 'creationDate',
			key: 'creationDate',
			render: date => <Moment format="DD-MM-yyyy HH:mm">
				{date}
			</Moment>,
			width: 150,
			defaultSortOrder: 'descend',
    		sorter: (a, b) => {
				return new Date(a.creationDate) - new Date(b.creationDate);
			}
		},
		{
			title: t('feedback.attachment'),
			dataIndex: 'attachment',
			key: 'attachment',
			width: 150,
			render: (a, record) => <div className="d-flex justify-content-center ">{a &&
				<Button onClick={() => openModalWithAttachment(record.id)} type="default" shape="circle" icon="file-image" />}</div>,
		}
	];

	return <div className="feedback-list">
		<Table rowKey="id" dataSource={feedbackList} columns={columns} />
		<Modal
			title={t('feedback.preview')}
			wrapClassName="feedback-image-modal"
			visible={modalOpen}
			footer={null}
			onCancel={onModalClose}
		>
			<img src={imageUrl} alt="" className="feedback-image" />
		</Modal>
	</div>;
}