export const getPositionTo = (positionTo, previousPosition, originalSize, maxSize, scale) => {
    const currentSize = originalSize * scale;

    if (currentSize > maxSize) {
        const bounds = getBounds(currentSize, maxSize, scale);

        if (positionTo > bounds.minBound) {
            return bounds.minBound;
        } else if (positionTo < bounds.maxBound) {
            return bounds.maxBound;
        }
        return positionTo;
    }

    return previousPosition;
};

export const getPositionToCenter = (positionTo, originalSize, maxSize, scale) => {
    const centerPosition = -(maxSize / 2 - (maxSize / 2) / scale) * scale;

    return getPositionTo(positionTo, centerPosition, originalSize, maxSize, scale)
};

export const getBounds = (currentSize, maxSize, scale) => {
    const centerPosition = -(maxSize / 2 - (maxSize / 2) / scale) * scale;
    const delta = (maxSize - currentSize) / 2;

    return {
        minBound: centerPosition - delta,
        maxBound: centerPosition + delta,
    }
};

export const getCenterPosition = (maxDimension, componentOriginalDimension, stage, oldScale, newScale) => {

    const scalePoint = {
        x: (maxDimension.width / 2) / oldScale - stage.x() / oldScale,
        y: (maxDimension.height / 2) / oldScale - stage.y() / oldScale,
    };

    scalePoint.x = -(scalePoint.x - (maxDimension.width / 2) / newScale) * newScale;
    scalePoint.y = -(scalePoint.y - (maxDimension.height / 2) / newScale) * newScale;

    scalePoint.x = getPositionToCenter(scalePoint.x, componentOriginalDimension.width, maxDimension.width, newScale);
    scalePoint.y = getPositionToCenter(scalePoint.y, componentOriginalDimension.height, maxDimension.height, newScale);

    return scalePoint;
};

export const getPositionToMouseCursor = (mousePointerPosition, stagePosition, oldScale, newScale) => {
    const scalePoint = {
        x: mousePointerPosition.x / oldScale - stagePosition.x / oldScale,
        y: mousePointerPosition.y / oldScale - stagePosition.y / oldScale,
    };

    scalePoint.x = -(scalePoint.x - mousePointerPosition.x / newScale) * newScale;
    scalePoint.y = -(scalePoint.y - mousePointerPosition.y / newScale) * newScale;

    return scalePoint;
};