import axios from "axios";

export const AuthenticationService = {
	doLogout() {
		return axios.post("/doLogout",
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true });
	},
	doLogin(username, password) {
		var bodyFormData = new FormData();
		bodyFormData.append('username', username);
		bodyFormData.append('password', password);

		return axios({
			method: 'post', url: '/doLogin',
			headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'multipart/form-data' }, data: bodyFormData
		});
	}
};