import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { isIE } from 'react-device-detect';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {store, persistor} from "./store/configureStore";
import App from "./components/App/App";
import { BrowserNotSupported } from "./components/BrowserNotSupported/BrowserNotSupported";
import { PersistGate } from 'redux-persist/integration/react'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import en from "./internationalization/en.json";
import ru from "./internationalization/ru.json";
import "./styles/index.scss";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'EN',   
	fallbackLng: "EN",                          
    resources: {
        EN: {
			translation: en
		},
		RU: {
			translation: ru
		},
    },
});


ReactDOM.render(
	isIE ? <BrowserNotSupported /> :
	<I18nextProvider i18n={i18next}>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</I18nextProvider>,
	document.getElementById("root")
);