import React from "react";
import { Select } from 'antd';

import './UserOrganization.scss';

export default function UserOrganization(props) {
	const { Option } = Select;

	var menuItems = props.list.map(o => {
		return (<Option key={o.id} value={o.id}>
			{o.name}
		</Option>);
	});

	return (<Select defaultValue={props.selectedOrganization}
		style={{ width: 120 }} className="user-organization-select" onChange={props.onOrganizationSelect}>
		{menuItems}
	</Select>);
}