import React from 'react';

import "./NotAllowedPage.scss";

export default function NotAllowedPage () {
    return (
        <div className="error-container">
            Page is not allowed!
        </div>
    );
}