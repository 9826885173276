import React from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

export function Preloader() {
    const {t} = useTranslation();

    return (<div className="cover-preloader">
        <Spin size="large" tip={t('common.preloader')} />
    </div>);
}