import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  SPAIN_GRID_FEATURE: {
    current: 1,
    pageSize: 10,
    position: 'both',
    totalPages: null
  },
  SGMU_GRID_FEATURE: {
    current: 1,
    pageSize: 10,
    position: 'both',
    totalPages: null
  },
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    update: (state, action) => {
      var newState = { ...state };

      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          newState[key] = action.payload[key];
        }
      }

      return newState;
    },
    reset: () => {
      return initialState;
    }
  }
})

export const { update, reset } = paginationSlice.actions

export default paginationSlice.reducer