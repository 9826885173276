import React, { useState } from "react";
import { Button, Layout, message, Upload, Icon } from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import { ImageAnalyzeApiService } from '../../api/ImageAnalyzeApi.service';
import ImageViewer from '../viewer/components/ImageViewer/ImageViewer';
import ImagePreview from '../../components/ImagePreview/ImagePreview';
import './ManualRun.scss';
import { useTranslation } from "react-i18next";
import EvaluationResult from '../../components/EvaluationResult/EvaluationResult';
import { MLButtonsSubpanel } from "../viewer/components/ControlPanel/MLButtonsSubpanel/MLButtonsSubpanel";
import { ColorModeButtonsSubpanel } from "../viewer/components/ControlPanel/ColorModeButtonsSubpanel/ColorModeButtonsSubpanel";

function ManualRun(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [isAnalyzingProgress, setAnalyzingProgress] = useState(false);
    const [analysis, setAnalysis] = useState(null);
    const [isToolboxShown, setIsToolboxShown] = useState(false);
    const [colorMode, setColorMode] = useState({});
    const [masks, setMasks] = useState({});
    const {t} = useTranslation();

    const setImageToViewer = (file) => {
        var img = {};
        img.domImage = new Image();
        img.domImage.src = file.img;
        img.domImage.onload = () => {
            var selectedImageMasks = masks[file.id];

            if (!selectedImageMasks || Object.keys(selectedImageMasks).length === 0) {
                masks[file.id] = {};
                setMasks(masks);
            }

            setSelectedImage({ img: img, id: file.id, name: file.name });
        }
    }

    const getBase64 = function (img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const onColorModeChanged = function (newClorMode) {
        var oldColorMode = { ...colorMode };
        oldColorMode[selectedImage.id] = newClorMode;
        setColorMode(oldColorMode);
    }

    const onFileRemoved = (fileId) => {
        var colorModes = { ...colorMode };
        colorModes[fileId] = undefined;
        setColorMode(colorMode); // clean color mode for removed image

        var oldMasks = { ...masks };
        oldMasks[fileId] = {};
        setMasks(oldMasks);// clean masks for removed image

        if (analysis && analysis[selectedImage.name]) {
            setAnalysis(null);
        }

        setFiles(files.filter(x => x.file.uid !== fileId));
        selectFirtAvailableFile(fileId)
    }

    const showMask = (key, value) => {
        var newMask = { ...masks };
        newMask[selectedImage.id][key] = value;

        setMasks(newMask);
    }

    const selectFirtAvailableFile = (fileId) => {
        if (selectedImage && fileId === selectedImage.id) {
            var availableFiles = files.filter(x => x.file.uid !== fileId);

            if (availableFiles && availableFiles.length > 0) {
                setImageToViewer({ img: availableFiles[0].preview, id: availableFiles[0].file.uid, name: availableFiles[0].file.name });
            } else {
                setSelectedImage(null);
            }
        }
    }

    const onSubmit = () => {
        if (!files || files.length === 0)
            return;

        setAnalyzingProgress(true);
        var filesToSend = files.map(x => x.file);

        ImageAnalyzeApiService.analyzeImages(filesToSend)
            .then(
                (result) => {
                    setAnalysis(result.data);

                    if (files) {
                        files.forEach(f => {
                            if (result.data[f.file.name] && result.data[f.file.name].desease_prediction > 0) {
                                var newMasks = {...masks};
                                newMasks[f.file.uid]['isROIShown'] = true;
        
                                setMasks(newMasks);
                            }
                        });
                    }
                })
            .catch(() => {
                message.error('Failed to analyze images');
            })
            .finally(() => {
                setAnalyzingProgress(false);
            });
    }

    const beforeUpload = function (fileToUpload) {
        setIsLoading(true);

        const isJpgOrPng = fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setIsLoading(false);
            return false;
        }
        const isLt5M = fileToUpload.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!');
            setIsLoading(false);
            return false;
        }

        return false;
    }

    const handleChange = info => {
        setIsLoading(false);

        getBase64(info.file, imagePreview => {
            var newFiles = [...files];
            newFiles.push({
                file: info.file, checkResult: info.file.response,
                preview: imagePreview
            });
            setFiles(newFiles);
            setImageToViewer({ img: imagePreview, id: info.file.uid, name: info.file.name });
        });
    };

    const getImageEvaluationResults = () => {
        if (!analysis)
            return {};
        return analysis[selectedImage.name];
    }

    const toggleToolbox = () => {
        setIsToolboxShown(!isToolboxShown);
    }

    return (
        <Layout className="manual-run-page">
            <div className="form upload-form">
                <h1>
                    {t('menu.manualRun')}
                </h1>

                <div className="image-uploads">
                    <Upload
                        accept="image/png, image/jpeg"
                        name="image"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        showUploadList={false}
                    >
                        <Button disabled={isLoading}
                            loading={isLoading}>
                             {t('upload.uploadImages')}
                        </Button>
                    </Upload>
                </div>
                <div className="file-previews">
                    {files.map(x => {
                        return <ImagePreview key={x.file.uid} file={x}
                            isSelected={selectedImage && x.file.uid === selectedImage.id}
                            onFileSelected={setImageToViewer}
                            onPreviewLoaded={setImageToViewer}
                            onFileRemoved={onFileRemoved}></ImagePreview>
                    })}
                </div>


                <Button type="danger" disabled={isAnalyzingProgress} className="analyze-button"
                    loading={isAnalyzingProgress}
                    onClick={onSubmit}
                >
                    {t('upload.analyze')}
                </Button>
            </div>
            <div className="viewer">
                {selectedImage && selectedImage.img && selectedImage.img.domImage &&
                    <React.Fragment>
                        <ImageViewer analysis={getImageEvaluationResults()} hideMagnifier={true} image={selectedImage.img} colorMode={colorMode[selectedImage.id]}
                            isROIShown={masks[selectedImage.id]['isROIShown']}
                            isHEShown={masks[selectedImage.id]['isHEShown']}
                            isMAShown={masks[selectedImage.id]['isMAShown']} 
                            isMXShown={masks[selectedImage.id]['isMXShown']}
                            isVesselsMaskShown={masks[selectedImage.id]['isVesselsMaskShown']} />
                        <div className={"toolbox " + (!isToolboxShown ? 'hidden' : '')}>
                            <button className="toolbox-toggle-button" onClick={() => toggleToolbox()}>
                                {isToolboxShown ? <Icon type="down" /> : <Icon type="up" />}

                            </button>
                            {analysis && <MLButtonsSubpanel analysis={getImageEvaluationResults()} showMask={showMask}
                                isROIShown={masks[selectedImage.id]['isROIShown']} isHEShown={masks[selectedImage.id]['isHEShown']} isMAShown={masks[selectedImage.id]['isMAShown']}
                                isMXShown={masks[selectedImage.id]['isMXShown']} isVesselsMaskShown={masks[selectedImage.id]['isVesselsMaskShown']} />}
                            <ColorModeButtonsSubpanel colorMode={colorMode[selectedImage.id]} setColorMode={onColorModeChanged} />
                        </div>
                        {
                            analysis &&
                            <div className="evaluation-result">
                                <EvaluationResult imageAnalysis={getImageEvaluationResults()}
                                    overallAnalysis={analysis.request_result}></EvaluationResult>
                            </div>
                        }
                    </React.Fragment>
                }
            </div>
        </Layout>
    )
}

export default withRouter(ManualRun);
