import React, { Component } from "react";
import {notification } from "antd";
import "./ViewerPage.css";
import "antd/dist/antd.css";
import { ExamImagesApiService } from "../../api/ExamImagesApi.service";
import { ExamApiService } from "../../api/ExamApi.service";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import  {connect} from "react-redux";
import moment from 'moment';
import ViewerBase from "./components/ViewerBase/ViewerBase";

class ViewerPage extends Component {
	constructor() {
		super();
		this.onExamChanged = this.onExamChanged.bind(this);
	}

	saveExams = (exam) => {
		ExamApiService.setExaminationStatus(exam.examinationId, {
			left: exam.leftEyeDiagnosis,
			right: exam.rightEyeDiagnosis,
			doctorNote: exam.doctorNote
		})
		.then(
			() => {
				this.props.history.push(`/dashboard/exams`);
			},
			() => {
				notification.open({
					message: 'Error',
					description:
						'Failed to save diagnosis',
				});
			}
		);
	}

	loadExamData(examId, setExamHistory, onImageLoaded, onImageLoadingFailed, setImages) {
		return Promise.all([
			ExamImagesApiService.getImages(examId),
			ExamApiService.getExaminationsAnalysisResult(examId),
			ExamApiService.getExamination(examId)
		])
			.then(
				([images, [leftResults, rightResults], exam]) => {
					ExamApiService.getExaminations(exam.patientNumber)
					.then(
						data => {
							data.sort((a, b) => {
								if (moment(a.examinationDate) > moment(b.examinationDate)) {
									return -1;
								}
								if (moment(a.examinationDate) < moment(b.examinationDate)) {
									return 1;
								}
			
								return 0;
							});

							setExamHistory(data);
						}
					);

					images.forEach(image => {
						image.domImage = new window.Image();

						ExamImagesApiService.getImage(image.id).then((imageBlob) => {
							image.domImage.src = URL.createObjectURL(imageBlob);
							image.imageBlob = imageBlob;
						}).catch(() => {
							onImageLoadingFailed(image);
						})

						image.domImage.onload = () => {
							onImageLoaded();
						}
						
						image.domImage.onerror = (e) => {
							if (onImageLoadingFailed)
								onImageLoadingFailed(image);
						}

						let examResults = {
							left: leftResults,
							right: rightResults
						};

						let fileResult = examResults[image.eyeSide][image.fileName];

						if (fileResult && fileResult.read_status !== "ERROR") {
							image.analysis = fileResult;

							image.hasRetinopathy = !!fileResult.desease_prediction;
							image.hasAmd = fileResult.has_signs_of_amd;
							image.hasGlaucoma = fileResult.has_signs_of_glaucoma;
							image.gradable = fileResult.gradable;
						}
					});

					setImages(images, exam);
				}
		);
	}	

	onExamChanged(value, selectedImageEyeSide) {
        this.props.history.push(`/dashboard/viewer/${value}/${selectedImageEyeSide}`);
    }


	render() {
		return (
			<ViewerBase onExamChanged={this.onExamChanged}
			onSaveExam={this.saveExams} onLoadExamData={this.loadExamData}></ViewerBase>
		)
	}
}

export default connect()(compose(withRouter)(ViewerPage));
