import React from 'react';
import { Route, Redirect } from "react-router-dom";

export default function GuardedRoute ({ component: Component, isAllowed, fallbackPath, ...rest }) {   
    return (
        <Route {...rest} render={(props) => (
            isAllowed
                ? <Component {...props} />
                : <Redirect to={fallbackPath} />
        )} />
    );
}
