import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import LoginPage from "../../pages/login/LoginPage";
import GuardedRoute from "../GuardedRoute/GuardedRoute";
import { useSelector } from 'react-redux';

export default function App() {
	const isLogedIn = useSelector((state) => state.account.isLogedIn);

	return (
		<BrowserRouter>
			<Switch>
				<GuardedRoute path='/dashboard' component={DashboardPage} isAllowed={isLogedIn} fallbackPath="/login" />
				<GuardedRoute path='/login' component={LoginPage} isAllowed={!isLogedIn} fallbackPath="/" />
				<Redirect exact from="/" to="dashboard" />
			</Switch>
		</BrowserRouter>
	);
}