import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		current: ''
	},
	reducers: {
		updateSelectedMenuItem: (state, action) => {
			return action.payload;
		},
		resetMenu: () => {
			return '';
		}
	},
})

export const { updateSelectedMenuItem, resetMenu } = menuSlice.actions

export default menuSlice.reducer