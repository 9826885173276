import axios from "axios";

export const DepartmentsService = {
    baseUrl: "/api/departments",
	getAll(organizationId) {
		return axios.get(this.baseUrl,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true, params: {
				organizationId: organizationId
			}},
		).then(x => x.data);
	},
	getById(id) {
		return axios.get(`${this.baseUrl}/${id}`,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	},
	save(data) {
		return axios.post(`${this.baseUrl}/save`,
			data,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	}
}