import React from "react";
import "antd/dist/antd.css";
import { Icon, Button } from 'antd';
import './ImagePreview.scss'


export default function ImagePreview(props) {
	const removeFile = (e) => {
		props.onFileRemoved(props.file.file.uid);

		e.stopPropagation();
	}

	const openFile = (e) => {
		props.onFileSelected({ img: props.file.preview, id: props.file.file.uid, name: props.file.file.name });

		e.stopPropagation();
	}

	return (
		<div className={`file-preview ${props.isSelected ? 'selected' : ''}`}>
			<img src={props.file.preview} alt="file preview" style={{ width: '100%' }} />
			<div className="overlay">
				<div className="secondary-buttons-container">
					<Button type="link" onClick={removeFile}>
						<Icon type='delete' /></Button>
				</div>
				<Button type="link" onClick={openFile}>
					<Icon type='eye' /></Button>
			</div>
		</div>
	)
}