import axios from "axios";
import { store } from "../store/configureStore";

export const ExamApiService = {
	getSSMUExaminations(patientNumber) {
		return this.getExaminationsBase(patientNumber, "/api/examinations/ssmu");
	},
	getExaminations(patientNumber) {
		return this.getExaminationsBase(patientNumber, "/api/examinations");
	},
	getExaminationsBase(patientNumber, url) {
		const state = store.getState();

		return axios.get(url,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, 
			withCredentials: true, params: { patientNumber: patientNumber } },
		).then(x => x.data);
	},
	getSSMUPaginatedExaminations(page, pageSize, sortField, sortDirection, searchParams) {
		const url = `/api/examinations/ssmu/paginated/`;

		return this.getPaginatedExaminationsBase(page, pageSize, sortField, sortDirection, searchParams, url)
	},
	getPaginatedExaminationsBase(
		page,
		pageSize,
		sortField,
		sortDirection,
		searchParams,
		url) {

		switch (sortDirection) {
			case 'ascend':
			case 'ASC':
				sortDirection = 'ASC';
				break;
			case 'descend':
			case 'DESC':
				sortDirection = 'DESC';
				break;
			default:
				sortDirection = undefined;
				break;
		}

		if (sortDirection === undefined || sortField === undefined) {
			sortDirection = undefined;
			sortField = undefined;
		}

		const state = store.getState();

		return axios.get(url,
			{
				headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization },
				withCredentials: true,
				params: {
					page: page - 1,
					pageSize: pageSize,
					sortField: sortField,
					sortDirection: sortDirection,
					...searchParams
				}
			},
		).then(x => x.data);
	},
	getPaginatedExaminations(page, pageSize, sortField, sortDirection, searchParams){
		const url = `/api/examinations/paginated/`;

		return this.getPaginatedExaminationsBase(page, pageSize, sortField, sortDirection, searchParams, url)
	},
	getSSMUExamination(examId) {
		return this.getExaminationBase(`/api/examinations/ssmu/${examId}`);
	},
	getExamination(examId) {
		return this.getExaminationBase(`/api/examinations/${examId}`);
	},
	getExaminationBase(url) {
		const state = store.getState();

		return axios.get(url,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	},

	setExaminationStatus(examId, data) { // POST /examinations/{id}/diagnosis/{eyeSide}
		const state = store.getState();

		return axios.post(
			`/api/examinations/${examId}/diagnosis`,
			data,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true }
		);
	},

	getExaminationsAnalysisResult(examinationId) { // /examinations/{id}/analysis-results/{eyeSide}
		const state = store.getState();

		return Promise.all([
			axios.get(`/api/examinations/${examinationId}/analysis-results/left`,
				{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
			).then(x => x.data),
			axios.get(`/api/examinations/${examinationId}/analysis-results/right`,
				{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
			).then(x => x.data)
		]);
	},
	createExam(exam, leftEyeImages, rightEyeImages) {
		const state = store.getState();
		var formData = new FormData();

		leftEyeImages.forEach(img => {
			formData.append('leftEyeImage', img);
		});

		rightEyeImages.forEach(img => {
			formData.append('rightEyeImage', img);
		});

		formData.append('exam', JSON.stringify(exam));

		return axios.post(
			`/api/examinations/create`,
			formData,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true }
		);
	},
	resendExam(examId) {
		const state = store.getState();

		return axios.post(
			`/api/examinations/resend/${examId}`,
			null,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true }
		);
	},
		
}
