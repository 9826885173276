import axios from "axios";
import { store } from "../store/configureStore";

export const ImageAnalyzeApiService = {
	analyzeImages(images) {
		const state = store.getState();
		var formData = new FormData();

		images.forEach(img => {
			formData.append('image', img);
		});

		return axios.post(
			`/analyzeImages`,
			formData,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization },
             withCredentials: true }
		);
	}	
}
