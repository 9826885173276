import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import {
	Link,
	useHistory,
	useRouteMatch
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PresetsService } from "../../../services/presetsService";
import GuardedRenderer from "../../../components/GuardedRenderer/GuardedRenderer";

export function PresetsPage() {
	let { url } = useRouteMatch();
	let history = useHistory();
	const {t} = useTranslation();

	const [presets, setPresets] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		PresetsService.get()
			.then((data) => {
				var orderedData = data.sort((a, b) => {
					return b.id - a.id;
				});

				setPresets(orderedData);
			})
			.finally(() => setIsLoading(false));
	}, [])

	const columns = [
		{
			title: t('admin.preset'),
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => {
				if (!a.name)
					return -1;
				if (!b.name)
					return 1;
					
				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
			}
		}
	];

	return (
		<div>
			<div className="d-flex justify-content-between mb-2">
				<h1>
					{t('admin.presets')}
				</h1>
				<GuardedRenderer requiredFeature="ALLOW_CHANGE_POLICY_CONFIG">
					<Link to={`${url}/create`}>	{t('admin.createPreset')}</Link>
				</GuardedRenderer>
			</div>
			<div>
				<Table
					loading={isLoading}
					pagination={false}
					dataSource={presets}
					columns={columns}
					scroll={{ y: '600px', x: false }}
					rowKey={(r) => r.id}
					onRow={(record) => {
						return {
							onClick: () => {
								history.push(`presets/${record.id}`);
							}
						}
					}} />
			</div>
		</div>
	);
}