import React, {Component} from "react";

import {SvgLoader} from 'react-svgmt';

import "./SimpleButton.css";
import "antd/dist/antd.css";

class SimpleButton extends Component {
    onMouseEnter = () => {
        document.body.style.cursor = "pointer";
    };

    onMouseLeave = () => {
        document.body.style.cursor = "default";
    };

    render() {
        const {text, icon, isButtonPressed, pressedClassName, onClickHandler} = this.props;

        return (
            <div className="control-button"
                 onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}
                 onClick={onClickHandler}>
                <SvgLoader className={"control-button-icon " + (isButtonPressed ? pressedClassName : "")}
                           path={icon}/>
                <p className="control-button-text">{text}</p>
            </div>
        )
    };
}

export default SimpleButton;