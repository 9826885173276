import React from "react";
import "antd/dist/antd.css";
import './EvaluationResult.scss';
import { useTranslation } from "react-i18next";

export default function EvaluationResult(props) {
    const {t} = useTranslation();

    const getResult = (analysis) => {
        if (analysis === null || analysis === undefined || analysis.desease_prediction === undefined) {
            return t('viewer.noData')
        }

        if (analysis.desease_prediction !== 0)
            return t('viewer.retinopathyDetected');

            return t('viewer.noRetinopathyDetected');
    }

    return (
        <div className="evaluation-result">
            <div className="evaluation-result-block">
                <div className="evaluation-title">
                   {t('viewer.overallResult')}
                </div>
                <div className="evaluation-data">
                    {getResult(props.overallAnalysis)}
                </div>
            </div>
            <div className="evaluation-result-block">
                <div className="evaluation-title">
                    {t('viewer.eyeAnalysisResult')}
                </div>

                <div className="evaluation-data">
                    {getResult(props.imageAnalysis)}
                </div>
            </div>
        </div>
    )
}