import axios from "axios";
import {store} from "../store/configureStore";

export const UserApiService = {
    getDepartments() {
        const state = store.getState();
        return axios.get("/api/departments",
            {
                headers: {"X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization},
                withCredentials: true,
                params: {organizationId: state.account.organization}
            },
        ).then(x => x.data);
    },
    getFeatures(organizationId) {
        return axios.get("/api/user/features",
            {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "organizationId": organizationId
                }, withCredentials: true
            },
        ).then(x => x.data);
    },
    getOrganizations() {
        return axios.get("/api/user/organizations",
            {headers: {"X-Requested-With": "XMLHttpRequest"}, withCredentials: true},
        ).then(x => x.data);
    }
};
