import React from 'react';
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import NotAllowedPage from '../../pages/error/NotAllowedPage';



export default function FeatureRoute(props) {
	const userFeatures = useSelector((x) => x.account.features);
	var savedProps = { ...props };

	return (
		<Route path={savedProps.path} render={({ ...props }) => {
			if (!savedProps.children) {
				return <NotAllowedPage></NotAllowedPage>;
			}

			if (!savedProps.requiredFeature) {
				return savedProps.children;
			}

			if (userFeatures && userFeatures.some(f => savedProps.requiredFeature.some(x => x === f.attributeValue))) {
				return savedProps.children;
			}

			return <NotAllowedPage></NotAllowedPage>;
		}} />
	);
}