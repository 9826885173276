import React from "react";
import { Select } from 'antd';
import { useTranslation } from "react-i18next";

export default function RolesFeatures(props) {
	const {t} = useTranslation();

	return (
		<div>
			<div>{t('common.role')}: <span className="role">{t('roles.' + props.role.translateStrId)}</span></div>

			<div className="features-container">
				<div>{t('common.features')}</div>
				{props.selectedItems && <Select
					disabled={props.isReadonly}
					mode="tags"
					value={props.selectedItems}
					onChange={(x) => props.handleChange(props.role.name, x)}
					style={{ width: 500 }}
				>
					{props.items.map(item => (
						<Select.Option key={item.attributeValue} value={item.attributeValue}>
							{t(item.translateStrId)}
						</Select.Option>
					))}
				</Select>}
			</div>
		</div>)
}