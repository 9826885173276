import React from "react";
import { Menu } from "antd";
import {
	Link
} from "react-router-dom";
import { useSelector } from 'react-redux';


export default function GuardedMenuItem({ key, url, name, requiredFeature, ...props }) {
	const userFeatures = useSelector((x) => x.account.features);

	if (!requiredFeature) {
		if ({ ...props }.children) {
			return { ...props }.children.props.children;
		}

		return (<Menu.Item key={key} {...props}>
			<Link to={url}>{name}</Link>
		</Menu.Item>);
	}

	if (userFeatures) {
		if (Array.isArray(requiredFeature) && userFeatures.some(f =>
			requiredFeature.some(x => x === f.attributeValue))) {
			if ({ ...props }.children) {
				return { ...props }.children.props.children;
			}


			return (<Menu.Item key={key} {...props}>
				<Link to={url}>{name}</Link>
			</Menu.Item>);
		}

		if (userFeatures.some(f =>
			f.attributeValue === requiredFeature)) {
			if ({ ...props }.children) {
				return { ...props }.children.props.children;
			}


			return (<Menu.Item key={key} {...props}>
				<Link to={url}>{name}</Link>
			</Menu.Item>);
		}
	}

	return null;
}
