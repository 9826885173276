import axios from "axios";
import { store } from "../store/configureStore";

export const SupportApiService = {
	getAppVersion() { // application version
		return axios.get("/api/support/app/version",
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	},
	sendFeedback(data) {
		const state = store.getState();
		var formData = new FormData();
		formData.append('creationDate', data.date);
		formData.append('message', data.message);
		formData.append('userEmail', data.email);

		if (data.attachment) {
			formData.append('attachment', data.attachment.originFileObj);
		}

		return axios.post(
			`/api/feedbacks/create`,
			formData,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true })
	},
	getFeedbackList() {
		const state = store.getState();

		return axios.get("/api/feedbacks",
			{ headers: { "X-Requested-With": "XMLHttpRequest" , 
			organizationId: state.account.organization}, withCredentials: true },
		).then(x => x.data);
	}, 
	processFeedback(feedbackId) {
		const state = store.getState();

		return axios.put("/api/feedbacks/" + feedbackId + "/process", {},
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization },
			 withCredentials: true },
		).then(x => x.data);
	}, 
	getAttachmentUrl(feedbackId) {
		const state = store.getState();

		return axios.get("/api/feedbackAttachments/" + feedbackId,
			{ headers: { "X-Requested-With": "XMLHttpRequest" , 
			organizationId: state.account.organization}, responseType: 'blob', withCredentials: true },			
		).then(x => {
			return URL.createObjectURL(x.data);
		});
	}
};
