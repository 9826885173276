import React, { useState, useEffect } from "react";
import { Table } from 'antd';
import {
	Link,
	useRouteMatch,
	useHistory
} from "react-router-dom";
import { OrganizationsService } from "../../../services/organizationsService";
import GuardedRenderer from "../../../components/GuardedRenderer/GuardedRenderer";
import { useTranslation } from "react-i18next";

export function OrganizationsPage() {
	let history = useHistory();
	let { url } = useRouteMatch();
	const [organizations, setOrganizations] = useState([]);
	const [loading, setLoading] = useState(true);
	const {t} = useTranslation();

	useEffect(() => {
		OrganizationsService.getAll()
			.then((data) => {
				var orderedData = data.sort((a, b) => {
					return b.id - a.id;
				});

				setOrganizations(orderedData);
			})
			.finally(() => setLoading(false));
	}, [])

	const columns = [
		{
			title: t('common.name'),
			dataIndex: 'name',
			key: 'name',
			width: 300,
			sorter: (a, b) => {
				if (!a.name)
					return -1;
				if (!b.name)
					return 1;
					
				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
			}
		},
		{
			title: t('common.description'),
			dataIndex: 'description',
			key: 'description',
			width: 300,
		}
	];
	
	return (
		<div>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h1 className="mb-0">
					{t('common.organizations')}
				</h1>
				<GuardedRenderer requiredFeature="ALLOW_CHANGE_POLICY_CONFIG">
					<Link to={`${url}/create`}>
						{t('admin.createOrganization')}
					</Link>
				</GuardedRenderer>
			</div>

			<Table
					loading={loading}
					pagination={false}
					dataSource={organizations}
					columns={columns}
					scroll={{ y: '600px', x: false }}
					rowKey={(r) => r.id}
					onRow={(record) => {
						return {
							onClick: () => {
								history.push(`organizations/${record.id}`);
							}
						}
					}} />
		</div>
	);
}