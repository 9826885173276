import React from "react";
import { Menu } from 'antd';
import { PresetsPage } from "./presets/PresetsPage";
import {
	Switch,
	Redirect,
	withRouter,
	useRouteMatch
} from "react-router-dom";
import { UsersPage } from "./users/UsersPage";
import CreateUserPage from "./users/CreateUserPage";
import EditUserPage from "./users/EditUserPage";
import { OrganizationsPage } from "./organizations/OrganizationsPage";
import  EditPresetPage  from "./presets/EditPresetPage";
import CreatePresetPage from "./presets/CreatePresetPage";
import CreateOrganizationsPage from "./organizations/CreateOrganizationPage";
import { CheckMlModelPage } from "./ZipToCsv/CheckMlModelPage"
import "./AdminDashboardPage.scss";
import EditOrganizationsPage from "./organizations/EditOrganizationPage";
import { DepartmentsPage } from "./departments/DepartmentsPage";
import CreateDepartmentPage from "./departments/CreateDepartmentPage";
import EditDepartmentPage from "./departments/EditDepartmentPage";
import GuardedMenuItem from "../../components/GuardedMenuItem/GuardedMenuItem";
import FeatureRoute from "../../components/GuardedRoute/FeatureRoute";
import { useSelector } from 'react-redux';
import { UserFeaturesService } from "../../services/userFeaturesEnum";
import { useTranslation } from "react-i18next";

function AdminDashboardPage() {
	let { path, url } = useRouteMatch();
	const features = useSelector((x) => x.account.features);
	const {t} = useTranslation();

	const getDefaultRedicrectRoute = () => {
		var redirect = UserFeaturesService.getDefaultAdminRedirectRoute(features);

		if (redirect)
			return redirect.route;

		return '';
	}


	return (
		<div className="admin-dashboard-container">
			<Menu
				className="admin-menu"
				mode="inline">
				<GuardedMenuItem key="preset" name={t('admin.presets')} url={`${url}/presets`}
					requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
				</GuardedMenuItem>
				<GuardedMenuItem key="organizations" name={t('common.organizations')} url={`${url}/organizations`}
					requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
				</GuardedMenuItem>
				<GuardedMenuItem key="departments" name={t('common.departments')} url={`${url}/departments`}
					requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
				</GuardedMenuItem>
				<GuardedMenuItem key="users" name={t('common.users')} url={`${url}/users`}
					requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
				</GuardedMenuItem>
				<GuardedMenuItem key="checkMlModel" name={t('common.mlcheck')} url={`${url}/checkMlModel`} requiredFeature="TEST_ML">
				</GuardedMenuItem>
			</Menu>
			<div className="route-container">
				<Switch>
					<FeatureRoute path={`${path}/presets/create`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<CreatePresetPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/users/create`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<CreateUserPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/users/:id`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<EditUserPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/presets/:id`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<EditPresetPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/presets`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<PresetsPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/users`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<UsersPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/organizations/create`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<CreateOrganizationsPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/organizations/:id`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<EditOrganizationsPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/organizations`} requiredFeature={["ALLOW_CHANGE_POLICY_CONFIG"]}>
						<OrganizationsPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/checkMlModel`} requiredFeature={["TEST_ML"]}>
						<CheckMlModelPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/departments/create`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<CreateDepartmentPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/departments/:id`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<EditDepartmentPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/departments`} requiredFeature={["ALLOW_CHANGE_ORGANIZATIONS"]}>
						<DepartmentsPage />
					</FeatureRoute>
					{getDefaultRedicrectRoute() &&
						<Redirect exact from={`${path}`} to={getDefaultRedicrectRoute()} />}
				</Switch>
			</div>
		</div>
	);
}

export default withRouter(AdminDashboardPage);