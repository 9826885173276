import React from "react";
import "./BrowserNotSupported.css";
import {Icon} from "antd";

export function BrowserNotSupported() {
	return (
		<div className="full-page-container">
			<Icon className="attention-icon" type="exclamation-circle" />
			<h1>Browser is not supported</h1>
			<div className="info-block">
				Use Microsoft Edge, Google Chrome, Mozilla Firefox or Safari to access application
			</div>
		</div>
	);
}