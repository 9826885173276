import { createSlice } from '@reduxjs/toolkit'

export const sortSlice = createSlice({
  name: 'sort',
  initialState: {
    SPAIN_GRID_FEATURE: {
      column: 'examinationDate',
      order: 'descend'
    },
    SGMU_GRID_FEATURE: {
      column: 'examinationDate',
      order: 'descend'
    }
  },
  reducers: {
    updateSort: (state, action) => {
      var newState = {...state};

      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          newState[key] = action.payload[key];
        }
      }

      return newState;
    },
  },
})

export const { updateSort } = sortSlice.actions

export default sortSlice.reducer