import React from "react";
import { Table } from 'antd';

export default function Grid(props) {
    return (
		<div className="worklist">
			<Table
				pagination={props.pagination}
				loading={props.isLoading}
				dataSource={props.data}
				onChange={props.handleTableChange}
				columns={props.columns}
				rowKey="examinationId"
				onRow={(record) => {
					return {
						onClick: () => props.onRowClick(record)
					}
				}}
			/>
		</div>
	);
}