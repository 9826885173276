import React, { useState } from "react";
import "./FeedBackForm.scss";
import { Button, Modal, Upload, Icon, Input, message, Form } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { SupportApiService } from '../../api/SupportApi.service';
import { useTranslation } from "react-i18next";

function FeedbackForm(props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const [file, setFile] = useState(null);

    const showModal = () => {
        setModalShown(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((errors, values) => {
            if (!errors) {
                setLoading(true);
                SupportApiService.sendFeedback({ email: values.email, message: values.feedbackMessage, attachment: file, date: new Date().toISOString() })
                    .then(() => {
                        message.success(t('feedback.feedbackSentMessage'));
                        setLoading(false);
                        cleanForm();
                        setModalShown(false);
                    })
                    .catch(() => {
                        message.error(t('feedback.failedToSendFeedbackMessage'));
                        setLoading(false);
                    });
            }
        });
    }

    const handleCancel = () => {
        cleanForm();
        setModalShown(false);
    };

    const cleanForm = () => {
        props.form.resetFields();
        setFile(null);
    }

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            setFile(info.file);
        } else if (info.file.status === 'error') {
            message.error(t('feedback.failedToUploadFile', {filename: info.file.name}));
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onFileRemove = () => {
        setFile(null);
    }

    return (
        <>
            <Button
                className={"feedback-button"}
                type="default"
                onClick={showModal}
                size="large">
                {t('feedback.leaveFeedback')}
                </Button>
            <Modal
                title={t('feedback.leaveFeedback')}
                style={{ top: 60 }}
                visible={modalShown}
                onCancel={handleCancel}
                footer={null}
            >
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={t('common.email')}>
                        {props.form.getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: t('common.errors.emailIncorrect'),
                                },
                                {
                                    required: true,
                                    message: t('common.errors.emailEmpty'),
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label={t('feedback.message')}>
                        {props.form.getFieldDecorator('feedbackMessage', {
                            rules: [
                                {
                                    required: true,
                                    message: t('feedback.messageEmpty'),
                                },
                                {
                                    max: 500,
                                    message: t('feedback.messageIsTooLong')
                                },
                            ],
                        })(<TextArea autoSize={{ minRows: 5 }} />)}
                    </Form.Item>
                    <Form.Item label={t('feedback.attachment')}>
                        {props.form.getFieldDecorator('file', {})(
                            <div>
                                <Upload 
									accept="image/*"
									customRequest={dummyRequest} onChange={onFileChange} showUploadList={false}>
                                    <Button>
                                        <Icon type="upload" />{t('feedback.uploadAttachment')}
                                    </Button>
                                </Upload>
                                {file && <div className="file">
                                    {file.name} <Button onClick={onFileRemove}><Icon type="delete" /></Button>
                                </div>}
                       </div> )}
                    </Form.Item>
                    <div className="form-controls">
                        <Button type="primary" loading={loading} htmlType="submit">
                        {t('feedback.sendFeedbackForm')}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
}

export default Form.create({ name: 'register' })(FeedbackForm);
