import { createStore, combineReducers } from '@reduxjs/toolkit'
import { accountReducer } from '../reducers/accountReducer'
import paginationReducer from '../reducers/paginationSlice'
import sortReducer from '../reducers/sortSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import menuSlice from '../reducers/menuSlice'

const worklistReducer = combineReducers({
  pagination: paginationReducer,
  sort: sortReducer
});


const rootReducer = combineReducers({
  worklist: worklistReducer,
  account: accountReducer,
  menu: menuSlice
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
	persistedReducer, /* preloadedState, */
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
let persistor = persistStore(store);

export {store, persistor};