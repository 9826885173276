import React, { Component } from "react";
import { Image, Rect } from "react-konva";
import { GRAYSCALE_FROM_GREEN_CHANNEL, GRAYSCALE_BY_LUMA_FORMULA, GREEN_ONLY } from "../../../../constants/colorModeType";
import { OcuscreenFilters } from "./OcuscreenFilters";


export class FilterImage extends Component {
	state = {
		image: undefined,
		colorFilter: undefined
	};

	componentDidMount() {
		this.setStateFromProps();
	}

	componentDidUpdate() {
		if (this.state.image !== this.props.image || this.state.colorFilter !== this.props.colorFilter) {
			this.setStateFromProps();
		}
	}
 
	setStateFromProps = () => {
		this.setState({
			image: this.props.image,
			colorFilter: this.props.colorFilter
		}, () => {
			this.imageRef.cache();
			this.imageRef.getLayer().batchDraw();
		});
	}

	shouldComponentUpdate(nextProps) {
		if (this.state.image !== nextProps.image || this.state.colorFilter !== nextProps.colorFilter)
			return true;

		return false;
	}

	render() {
		if (!this.state.image) {
			return (
				<></>
			);
		}

		const colorFilters = [];
		switch (this.state.colorFilter) {
			case GRAYSCALE_FROM_GREEN_CHANNEL:
				colorFilters.push(OcuscreenFilters.grayscaleImageData);
				break;
			case GRAYSCALE_BY_LUMA_FORMULA:
				colorFilters.push(OcuscreenFilters.grayscaleImageDataByLumaFormula);
				break;
			default:
				break;
		}

		return (
			<>
				<Image
					ref={node => {
						this.imageRef = node;
					}}
					image={this.state.image}
					scaleX={this.props.scale}
					scaleY={this.props.scale}
					filters={colorFilters} />

				{this.state.colorFilter === GREEN_ONLY && (
					<Rect width={this.state.image.width}
						listening={false} 
						height={this.state.image.height}
						scaleX={this.props.scale}
						scaleY={this.props.scale}
						fill="#00ff00"
						globalCompositeOperation="multiply" />
				)}
			</>
		);
	}
}
