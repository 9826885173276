import React, { useEffect, useState } from "react";
import { Form, Layout, Input, Button, Select, notification } from 'antd';
import { PresetsService } from "../../../services/presetsService";
import { OrganizationsService } from "../../../services/organizationsService";
import {
	useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";

export function CreateOrganizationsPage(props) {
	const { getFieldDecorator } = props.form;
	const [isLoading, setIsLoading] = useState(false);
	const [isSavingInProgress, setIsSavingInProgress] = useState(false);
	const [presets, setPresets] = useState([]);
	const [locales, setLocales] = useState([]);
	const [selectedPresetId, setSelectedPreset] = useState(null);
	const [selectedLocale, setSelectedLocale] = useState(null);
	let history = useHistory();
	const { t } = useTranslation();

	const createOrganization = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors && selectedPresetId) {
				setIsSavingInProgress(true);

				OrganizationsService.save({
					name: values.name,
					description: values.description,
					securityPresetId: selectedPresetId,
					locale: selectedLocale
				})
					.then(() => {
						history.push('/dashboard/admin/organizations');
						notification['success']({ message: t('admin.organizationCreatedMessage') })
					})
					.catch(() => { notification['warning']({ message: t('admin.failedToCreateOrganization') }) })
					.finally(() => {
						setIsSavingInProgress(false);
					});
			}
		});
	}

	const selectPreset = (x) => {
		setSelectedPreset(x);
	}

	const selectLocale = (x) => {
		setSelectedLocale(x);
	}

	useEffect(() => {
		Promise.all([OrganizationsService.getLocales(), PresetsService.get()])
			.then(([locales, presets]) => {
				setLocales(locales);
				setPresets(presets);
				setIsLoading(false);
			})
	}, []);

	return (
		<Layout>
			{isLoading ? <div>{t('common.loading')}</div> : <div>
				<h1 className="mb-2">
					{t('admin.createOrganization')}
				</h1>
				<Form className="create-edit-form" onSubmit={createOrganization}>
					<div>
						<Form.Item label={t('admin.organizationName')}>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('admin.organizationNameNotProvided') }],
							})(
								<Input />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('common.description')}>
							{getFieldDecorator('description', {
								rules: [{ required: true, message: t('admin.organizationDescriptionNotProvided') }],
							})(
								<Input />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.preset')}>
							{getFieldDecorator('preset', {
								rules: [{ required: true, message: t('admin.presetNotSelected') }],
							})(
								<Select
									onChange={(x) => selectPreset(x)}
									style={{ width: 500 }}
								>
									{presets.map(item => (
										<Select.Option key={item.id} value={item.id}>
											{item.name}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.locales')}>
							{getFieldDecorator('locale', {
								rules: [{ required: true, message: t('admin.localeNotSelected') }],
							})(
								<Select
									onChange={(x) => selectLocale(x)}
									style={{ width: 500 }}
								>
									{locales.map(item => (
										<Select.Option key={item.code} value={item.code}>
											{item.description}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</div>
					<div className="mt-2">
						<Button htmlType="submit" loading={isSavingInProgress}
							type="primary">{t('common.save')}</Button>
					</div>
				</Form>
			</div>}
		</Layout>
	);
}

export default Form.create()(CreateOrganizationsPage);