import React, { useState, useEffect } from "react";
import { Form, Layout, Input, Button, notification } from 'antd';
import { PresetsService } from "../../../services/presetsService";
import RolesFeatures from "../../../components/Presets/RoleFeatures"
import "./Presets.scss";
import {
	useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreatePresetPage(props) {
	const { getFieldDecorator } = props.form;
	const [features, setFeatures] = useState([]);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [featuresPerRole, setFeaturesPerRole] = useState({});
	const [isSavingInProgress, setSavingInProgress] = useState(false);
	let history = useHistory();
	const {t} = useTranslation();

	const handleChange = (roleKey, items) => {
		var featuresPerRoleCopy = { ...featuresPerRole }

		var mappedFeatures = features.map(x => {
			var obj = {};

			obj.attribute = {
				attributeName: x.attributeName,
				attributeValue: x.attributeValue
			};

			obj.enabled = items.some(i => i === x.attributeValue);

			return obj;
		});

		featuresPerRoleCopy[roleKey] = mappedFeatures;

		setFeaturesPerRole(featuresPerRoleCopy);
	};

	const createPreset = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors) {
				setSavingInProgress(true);
				PresetsService.save({ features: featuresPerRole, name: values.name })
					.then(() => {
						notification['success']({ message: t('admin.presetSavedMessage')});
						history.push('/dashboard/admin/presets');
					})
					.finally(() => setSavingInProgress(false))
					.catch(() => {
						notification['warning']({ message: t('admin.failedToSavePresetMessage') });
					});
			}
		});
	}

	useEffect(() => {
		Promise.all([PresetsService.getVisibleRoles(), PresetsService.getFeatures()])
			.then(([roles, features]) => {
				setRoles(roles);
				setFeatures(features);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<Layout>
			{isLoading ? <div>
				{t('common.loading')}
			</div> : <div>
				<h1 className="mb-2">
					{t('admin.createPreset')}
				</h1>
				<Form className="preset-form" onSubmit={createPreset}>
					<div>
						<Form.Item label={t('admin.presetName')}>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('admin.presetNameInvalid') }],
							})(
								<Input />,
							)}
						</Form.Item>
					</div>
					<div>
						{roles.map(role => {
							return (
								<RolesFeatures
									key={role.id}
									role={role}
									handleChange={handleChange}
									items={features}
									selectedItems={featuresPerRole[role.name] ? featuresPerRole[role.name].filter(x => x.enabled).map(x => x.attribute.attributeValue) : []}>
								</RolesFeatures>
							)
						})}
					</div>
					<div>
						<Button htmlType="submit" loading={isSavingInProgress}
							type="primary">
								{t('common.save')}
							</Button>
					</div>
				</Form>
			</div>}
		</Layout>
	);
}

export default Form.create()(CreatePresetPage);