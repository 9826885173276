import React, { Component } from "react";
import { OcuscreenFilters } from "./OcuscreenFilters";
import Konva from 'konva'

export class Magnifier extends Component {

	state = {
		image: undefined
	};

	componentDidMount() {
		this.width = this.props.width / this.props.sizeСoefficient;
		this.height = this.props.height / this.props.sizeСoefficient;

		this.stage = new Konva.Stage({
			container: 'container',
			width: this.width,
			height: this.height,
			scaleX: this.props.scale * this.props.sizeСoefficient,
			scaleY: this.props.scale * this.props.sizeСoefficient,

		});
		this.layer = new Konva.Layer();
		this.stage.add(this.layer);

		this.setState({
			image: this.props.image
		}, () => {

			const scaleDelta = this.getScaleDelta(this.width, this.height);

			this.image = new Konva.Image({
				filters: [OcuscreenFilters.grayscaleImageDataByLumaFormula],
				image: this.state.image.domImage,
				scaleX: scaleDelta,
				scaleY: scaleDelta
			})
			this.layer.add(this.image);

			this.image.cache();
			this.layer.batchDraw();
		});
	}

	componentDidUpdate() {
		if (this.state.image !== this.props.image) {
			this.setState({
				image: this.props.image
			}, () => {
				if (this.image) {
					this.image.image(this.state.image.domImage)
				} else {
					this.image = new Konva.Image({
						image: this.state.image.domImage
					})

					this.layer.add(this.image);
				}

				this.image.cache();
				this.layer.batchDraw();
			});
		}


 		const { stagePosition, cursorPosition, scale, sizeСoefficient } = this.props;
		this.layer.x((cursorPosition.x + this.width / 8) / (sizeСoefficient));
		this.layer.y((cursorPosition.y + this.height / 8) / (sizeСoefficient));

		this.stage.x(stagePosition.x * scale);
		this.stage.y(stagePosition.y * scale);

		this.layer.batchDraw();
	}

	componentWillUnmount() {
		this.stage.destroy();
		this.image.destroy();
		this.layer.destroy();
	}

	getScaleDelta = (maxWidth, maxHeight) => {
		const loadedImage = this.state.image.domImage;

		if (loadedImage) {
			this.delta = maxWidth / loadedImage.naturalWidth;
			this.delta = (loadedImage.naturalHeight * this.delta < maxHeight) ?
				this.delta :
				maxHeight / loadedImage.naturalHeight;

			return this.delta;
		}
	};

	render() {
		return (
			<div id="container"></div>
		);
	}
}
