const AdminFeaturesEnum = {
	ALLOW_CHANGE_POLICY_CONFIG: [{ type: 'admin-presets', menuKey: 'admin', route: '/dashboard/admin/presets' }],
	ALLOW_CHANGE_ORGANIZATIONS: [{ type: 'admin-departments', menuKey: 'admin', route: '/dashboard/admin/departments' }, 
		{ type: 'admin-users', menuKey: 'admin', route: '/dashboard/admin/users' }],
	TEST_ML: [{ type: 'checkMlModel', menuKey: 'admin', route: '/dashboard/admin/checkMlModel' }],
}

const GridFeaturesEnum = {
	SPAIN_GRID_FEATURE: [{ type: 'exams', menuKey: 'exams', route: '/dashboard/exams' }],
	SGMU_GRID_FEATURE: [{ type: 'exams', menuKey: 'sgexams', route: '/dashboard/examsList' }]
}

const ViewerFeaturesEnum = {
	VIEW_EXAM: [{ type: 'exams', menuKey: 'exams', route: '/dashboard/viewer', 
	routePattern: new RegExp('/dashboard/viewer/.+') }],
	VIEW_EXAM_SGMU: [{ type: 'exams', menuKey: 'sgexams', route: '/dashboard/sgviewer', 
	routePattern: new RegExp('/dashboard/sgviewer/.+') }]
}

const UserFeaturesEnum = {
	...AdminFeaturesEnum,
	ALLOW_VIEW_POLICY_CONFIG: [{ type: 'admin-default', menuKey: 'admin', route: '/dashboard/admin' }],
	FEEDBACK_CHANGE_STATUS: [{ type: 'feedback', menuKey: 'feedback', route: '/dashboard/feedback' }],
	CREATE_EXAM: [{ type: 'upload', menuKey: 'upload', route: '/dashboard/upload' }],
	MANUAL_RUN: [{ type: 'manual-run', menuKey: 'manual-run', route: '/dashboard/manualRun' }],
	...GridFeaturesEnum,
	...ViewerFeaturesEnum
}

const AllowedForRedirectUserFeatures = {
	...GridFeaturesEnum,
	...AdminFeaturesEnum,
	ALLOW_VIEW_POLICY_CONFIG: [{ type: 'admin-default', menuKey: 'admin', route: '/dashboard/admin' }],
	FEEDBACK_CHANGE_STATUS: [{ type: 'feedback', menuKey: 'feedback', route: '/dashboard/feedback' }],
	CREATE_EXAM: [{ type: 'upload', menuKey: 'upload', route: '/dashboard/upload' }],
	MANUAL_RUN: [{ type: 'manual-run', menuKey: 'manual-run', route: '/dashboard/manualRun' }],
}

export const UserFeaturesService = {
	getDefaultRedirectRoute(features, currentRoute) {
		if (!features)
			return '';

		var featureForCurrentRoute = this.getFeatureForRoute(currentRoute);

		if (featureForCurrentRoute) {
			// if user already on the route which is allowed
			if (features.some(x => x.attributeValue === featureForCurrentRoute))
			{
				return this.getConfigForRoute(currentRoute);
			}

			var currentRouteType = UserFeaturesEnum[featureForCurrentRoute][0].type;

			for (let i = 0; i < features.length; i++) {
				if (AllowedForRedirectUserFeatures[features[i].attributeValue] &&
					AllowedForRedirectUserFeatures[features[i].attributeValue].some(x => x.type === currentRouteType)) {
					return AllowedForRedirectUserFeatures[features[i].attributeValue][0];
				}
			}
		}

		for(let key in AllowedForRedirectUserFeatures) {
			if (features.some(f => f.attributeValue === key)) {
				return AllowedForRedirectUserFeatures[key][0];
			}

		}
	},
	getFeatureForRoute(route) {
		if (!route)
			return null;

		for (var key in UserFeaturesEnum) {
			if (UserFeaturesEnum[key].some(x => x.route === route || (x.routePattern && route.match(x.routePattern))))
				return key;
		}

		return null;
	},
	getConfigForRoute(route) {
		for (var key in UserFeaturesEnum) {
			if (UserFeaturesEnum[key].some(x => x.route === route))
				return UserFeaturesEnum[key].filter(x => x.route === route)[0];
		}

		return null;
	},
	getDefaultAdminRedirectRoute(features) {
		if (features) {
			for (let i = 0; i < features.length; i++) {
				if (AdminFeaturesEnum[features[i].attributeValue]) {
					return AdminFeaturesEnum[features[i].attributeValue][0];
				}
			}
		}

		return '';
	},
	getExamsListRouteOrDefault(features) {
		if (features) {
			if (features.some(x => x.attributeValue === 'SPAIN_GRID_FEATURE')) {
				return UserFeaturesEnum.SPAIN_GRID_FEATURE[0];
			}

			if (features.some(x => x.attributeValue === 'SGMU_GRID_FEATURE')) {
				return UserFeaturesEnum.SGMU_GRID_FEATURE[0];
			}
		}

		return this.getDefaultRedirectRoute(features);
	},
	getMenuItemKeyByPath(path) {
		if (!path)
			return '';

		for (const k of Object.keys(UserFeaturesEnum)) {
			if(UserFeaturesEnum[k].some(x => path === x.route))
			{
				return UserFeaturesEnum[k].filter(x => path === x.route)[0].menuKey;
			}

		}

		return '';
	}
}

