import axios from "axios";
import { store } from "../store/configureStore";

export const PresetsService = {
	get() {
		const state = store.getState();

		return axios.get("/api/policy/presets",
			{
				headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }
				, withCredentials: true,
			},
		).then(x => x.data);
	},
	getById(id) {
		const state = store.getState();
		return axios.get(`/api/policy/presets/${id}`,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	},
	getFeatures() {
		const state = store.getState();
		return axios.get("/api/policy/features",
			{
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					organizationId: state.account.organization
				}, withCredentials: true
			},
		).then(x => x.data);
	},
	getVisibleRoles() {
		const state = store.getState();
		return axios.get(`/api/user/roles`,
			{
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					organizationId: state.account.organization
				}, withCredentials: true
			},
		).then((x) => {
			if (!x.data || !Array.isArray(x.data))
				return [];

			return x.data.filter(d => !d.hidden);
		});
	},
	save(data) {
		const state = store.getState();
		return axios.post(`/api/policy/presets/save`,
			data,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true },
		).then(x => x.data);
	}
}