import { UserApiService } from "../api/UserApi.service.";
import { OrganizationsService} from "./organizationsService";
import { store } from "../store/configureStore";

export const UserService = {
	getDefaultOrganizationAndFeatures() {
		return OrganizationsService.getAll()
			.then((data) => {
				if (data) {
					let defaultOrganization = data[0];

					if (!defaultOrganization) {
						return {
							organizations: data,
							organization: defaultOrganization,
							features: []
						};
					}

					return UserApiService.getFeatures(defaultOrganization.id)
						.then((features) => {
							return {
								organizations: data,
								organization: defaultOrganization,
								features: features
							};
						});
				}
			});
	},
	loadFeatures(organizationId) {
		return UserApiService.getFeatures(organizationId)
			.then((features) => {
				return features;
			});
	},
	hasFeatures(features) {
		const state = store.getState();

		var accountFeatures = state.account.features;

		return accountFeatures.some(f => features.some(t => t === f.attributeValue));
	}
}
