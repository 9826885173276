import React, { useEffect, useState } from "react";
import { Line } from "react-konva";

export default function ROIMaskImage(props) {
    const [ROIContours, setROIContours] = useState([]);
    const [HEContours, setHEContours] = useState([]);
    const [MAcontours, setMA_contours] = useState([]);
    const [MXcontours, setMX_contours] = useState([]);

    useEffect(() => {
        setROIContours(getLineContours(props.analysis.ROI_contours));
        setHEContours(getLineContours(props.analysis.HE_contours));
        setMA_contours(getLineContours(props.analysis.MA_contours));
        setMX_contours(getLineContours(props.analysis.MX_contours));
    }, [props.analysis]);


    const setStrokeWidth = (scale) => {
        const minWidth = 0.01;
        const calculatedWidth = 1.5 / scale;

        return calculatedWidth < minWidth ? minWidth : calculatedWidth;
    };

    const getLineContours = (contours) => {
        if (!contours || contours.length === 0)
            return [];
            
        let result = [];
        for (let contour of contours) {
            let areaPoints = [];
            for (let point of contour) {
                if (!isNaN(point[0])) {
                    areaPoints.push(point[0] * props.delta);
                    areaPoints.push(point[1] * props.delta);
                }
            }

            result.push(areaPoints);
        }

        return result;
    }

    const renderContours = (isContoursShown, contours, contoursColor) => {
        const color = isContoursShown ? contoursColor : 'transparent';

        let result = [];

        for (let contour of contours) {
            result.push(
                <Line key={contours.indexOf(contour)}
                    listening={false}
                    points={contour}
                    stroke={color}
                    strokeWidth={setStrokeWidth(props.stageScale)}
                    tension={0.1}
                    closed
                />
            );
        }

        return result;
    };

    return (
        <>
            {renderContours(props.isROIShown, ROIContours, "#fff")}
            {renderContours(props.isHEShown, HEContours, "#0ff")}
            {renderContours(props.isMAShown, MAcontours, "#f0f")}
            {renderContours(props.isMXShown, MXcontours, "#ff0")}
        </>
    );
}
