import React from "react";

export function CheckMlModelPage() {
        return (
        <div class="container">
                <h1>Download the zip file with images to get a diagnosis...</h1>
                <form enctype="multipart/form-data" action="/ziptocsv/processzip" method="post">
                        <input type="file" name="files" aria-label="Upload" />
                        <button type="submit">Upload</button>
                        <br />
                </form>
        </div>
        );
}