import React from "react";

export function Diagnosis (props) {

	const {diagnosisSet, title} = props;

	if(!diagnosisSet) {
		return (
			<></>
		);
	}

	return (<span  style={{marginLeft: "1rem"}}>
		{title}:
		{diagnosisSet.hasGlaucoma && 'G '}
		{diagnosisSet.hasRetina && 'DR '}
		{diagnosisSet.hasAmd && 'AMD '}
		{diagnosisSet.hasCrvo && 'CRVO '}
		{diagnosisSet.hasBrvo && 'BRVO '}
	</span>);
}