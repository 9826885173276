import React, { useEffect, useState } from "react";
import { Image } from "react-konva";

export default function VesselsMaskImage(props) {
    const [loadedVesselsMaskImage, setLoadedVesselsMaskImage] = useState(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (!props || !props.analysis || !props.analysis.vessels_mask)
            return;

        setScale(props.scaleCoef);

        if (!loadedVesselsMaskImage || loadedVesselsMaskImage.src !== "data:image/png;base64," + props.analysis.vessels_mask) {
            let newImage = new window.Image();
            newImage.src = "data:image/png;base64," + props.analysis.vessels_mask;
            newImage.onload = () => {
                newImage.width = props.sourceImage.width;
                newImage.height = props.sourceImage.height;
                setLoadedVesselsMaskImage(newImage);
            };
        } else {
            loadedVesselsMaskImage.width = props.sourceImage.width;
            loadedVesselsMaskImage.height = props.sourceImage.height;
        }
    }, [props.sourceImage, props.analysis]);

    return (
        <Image
            scaleX={scale}
            scaleY={scale}
            image={loadedVesselsMaskImage}
            opacity={props.isVesselsMaskShown ? 1 : 0}
            globalCompositeOperation={"exclusion"} />
    )
}