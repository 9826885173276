import React, { useState, useEffect } from "react";
import Moment from 'react-moment';
import { Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { Diagnosis } from '../../../../components/Diagnosis/Diagnosis';
import { useTranslation } from "react-i18next";
const { Option } = Select;


function ExamDate(props) {
	const {t} = useTranslation();
	const [patientExams, setExams] = useState([]);

	const { exam, selectedImageEyeSide, examsHistory } = props;

	useEffect(() => {
		setExams(examsHistory);
	}, [examsHistory]);

	const handleChange = (value) => {
		props.onExamChanged(value, selectedImageEyeSide);
	}

	const isDataLoaded = patientExams && patientExams.length !== 0;

	return (
		<div className="exam-date-container">
			<span style={{ marginRight: "1rem" }}>{t('common.exam')}:</span>
			<span>
				<Select
					defaultValue={exam.examinationId}
					bordered={false}
					onChange={handleChange}
					style={{ width: 300 }}
				>
					{patientExams.map(x => (
						<Option value={x.examinationId} key={x.examinationId}>
							<Moment format="DD-MM-yyyy">
								{x.examinationDate}
							</Moment>
							<Diagnosis title={"OD"} diagnosisSet={x.rightEyeDiagnosis}></Diagnosis>
							<Diagnosis title={"OS"} diagnosisSet={x.leftEyeDiagnosis}></Diagnosis>
						</Option>
					))}

					{!isDataLoaded && (
						<Option value={exam.examinationId}>
							<Moment format="DD-MM-yyyy">
								{exam.examinationDate}
							</Moment>
							<Diagnosis title={"OD"} diagnosisSet={exam.rightEyeDiagnosis}></Diagnosis>
							<Diagnosis title={"OS"} diagnosisSet={exam.leftEyeDiagnosis}></Diagnosis>
						</Option>
					)}

				</Select>
			</span>
		</div>
	)
}

export default withRouter(ExamDate);
