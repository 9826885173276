import React from "react";
import SimpleButton from "../SimpleButton/SimpleButton";
import PaintIcon from "../../../../../res/paint.svg";
import {GRAYSCALE_BY_LUMA_FORMULA, GRAYSCALE_FROM_GREEN_CHANNEL, GREEN_ONLY} from "../../../../../constants/colorModeType";
import "./ColorModeButtonsSubpanel.css";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";

export function ColorModeButtonsSubpanel (props) {
    const { t } = useTranslation();

    const handleGrayscaleFromGreenChannelButton = () => {
        handleColorModeButton(GRAYSCALE_FROM_GREEN_CHANNEL);
    };

    const handleGrayscaleByLumaFormulaButton = () => {
        handleColorModeButton(GRAYSCALE_BY_LUMA_FORMULA);
    };

    const handleGreenOnlyButton = () => {
        handleColorModeButton(GREEN_ONLY);
    };

    const handleColorModeButton = (newColorMode) => {
        const {colorMode, setColorMode} = props;

        !colorMode || colorMode !== newColorMode ?
            setColorMode(newColorMode) :
            setColorMode(undefined);
    };

    const renderColorModeButton = (isButtonPressed, text, onClickHandler) => {
        return (
            <SimpleButton text={text}
                               icon={PaintIcon}
                               isButtonPressed={isButtonPressed}
                               pressedClassName="paint-button-pressed"
                               onClickHandler={onClickHandler}/>
        )
    };

    return (
        <div className="simple-buttons">
            {renderColorModeButton(props.colorMode === GRAYSCALE_FROM_GREEN_CHANNEL, t('viewer.grayscale'), handleGrayscaleFromGreenChannelButton)}
            {renderColorModeButton(props.colorMode === GRAYSCALE_BY_LUMA_FORMULA, t('viewer.grayscaleByFormula'), handleGrayscaleByLumaFormulaButton)}
            {renderColorModeButton(props.colorMode === GREEN_ONLY, t('viewer.greenOnly'), handleGreenOnlyButton)}
        </div>
    )
}
