import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import ".././WorklistPage.scss";
import { useHistory } from 'react-router-dom';
import { ExamApiService } from "../../../api/ExamApi.service";
import { useSelector, useDispatch } from 'react-redux'
import { update } from "../../../reducers/paginationSlice";
import { updateSort } from "../../../reducers/sortSlice";
import Grid from "../../../components/Grid/Grid";
import { Input, Button, Icon, Tag, Divider } from 'antd';
import Moment from 'react-moment';
import { Diagnosis } from '../../../components/Diagnosis/Diagnosis';
import GuardedRenderer from "../../../components/GuardedRenderer/GuardedRenderer";
import { UserService } from "../../../services/userService";
import { useTranslation } from "react-i18next";

export default function SGWorklistPage() {
	const {t} = useTranslation();
	const pagination = useSelector((x) => x.worklist.pagination['SGMU_GRID_FEATURE']);
	const sort = useSelector((x) => x.worklist.sort['SGMU_GRID_FEATURE']);
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false);
	const [exams, setExams] = useState([]);
	const [searchParams, setSearchParams] = useState('');
	const [examResendPending, setExamResendPending] = useState(false);
	let searchInput = undefined;
	let history = useHistory();

	const examStates = {
		'ANALYZED': {
			title: t('list.new'),
			color: 'green'
		}, 
		'ANALYSING': {
			title: t('list.pending'),
			color: 'orange'
		},
		'FAILED': {
			title: t('list.failed'),
			color: 'red'
		},
		'PROCESSED': {
			title: t('list.processed')
		}
	};

	const onSearch = (searchKey, confirm) => {
		loadExams(
			1,
			sort.column,
			sort.order,
			{ [searchKey]: searchParams[searchKey] })
		.then(
			() => {
				confirm();
			}
		)
	};

	const handleReset = clearFilters => {
		setSearchParams('');
		loadExams(
			1,
			sort.column,
			sort.order,
			{})
		.then(
			() => {
				clearFilters();
			}
		)
	};

	const handleSearchFieldChange = value => {
		setSearchParams(value);
	}

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						searchInput = node;
					}}
					value={searchParams[dataIndex]}
					onChange={e => 
						handleSearchFieldChange(e.target.value ? {[dataIndex]: e.target.value} : {[dataIndex]:''})}
					onPressEnter={() => onSearch(dataIndex, confirm)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<div>
					<Button
						type="primary"
						onClick={() => onSearch(dataIndex, confirm)}
						size="small"
						style={{ width: 90 }}>
						{t('common.search')}
						</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						{t('common.reset')}
					</Button>
				</div>
			</div>
		),
		filterIcon: () => <Icon className="icon" type="search" />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.select(), 100);
			}
		},
		render: text => text
	});

	
	const getActionsColumn = (a, record) => {
		if (UserService.hasFeatures(['RESEND_EXAM']) && exams.some(e => e.examinationStatus === 'FAILED')) {
			return {
				title: t('list.actions'),
				render: (a, record) => {return <div>
					{
						<GuardedRenderer requiredFeature="RESEND_EXAM">
							{record.examinationStatus === 'FAILED' && 
								<>
								<Divider type="vertical" />
								<Button onClick={(e) => handleResendClick(e, record)} 
										className="p-0"	type='link' loading={examResendPending[record.examinationId]}>
													{t('list.resend')}
								</Button>
							</>}
						</GuardedRenderer>
					}
			</div>}
			}
		}
	}

	const columns =  [
            {
                title: t('list.status'),
                dataIndex: 'examinationStatus',
                key: 'examinationStatus',
                sorter: true,
                sortOrder: undefined,
                render: status => {
                    return examStates[status] ? <Tag color={examStates[status].color}>
                        {examStates[status].title.toUpperCase()}
                    </Tag> : <Tag>
                        {status}
                    </Tag>
                },
                width: 150
            },
            {
                title: t('list.examDate'),
                dataIndex: 'examinationDate',
                sorter: true,
                sortOrder: undefined,
                render: date => (
                    <Moment format="DD-MM-yyyy HH:mm">
                        {date}
                    </Moment>
                ),
                width: 200
            },
            {
                title: t('list.patientNumber'),
                dataIndex: 'patientNumber',
                width: 150
            },
            {
				title: t('list.patientName'),
				dataIndex: 'patientName',
				...getColumnSearchProps('patientName'),
				width: 200
			},
            {
				title: t('list.gender'),
				dataIndex: 'patientGender',
				width: 200
			},
			{
				title: t('list.birthdate'),
				dataIndex: 'patientBirthDate',
				sorter: true,
				sortOrder: undefined,
				render: date => {
					return date ?   <Moment format="DD-MM-yyyy">
										{date}
									</Moment> : "-"
				},
				width: 200
			},
            {
                title: t('list.diagnosis'),
                dataIndex: 'diagnosisleft',
                render: (a, record) => {
                    return <div>
                        <div>
                            <Diagnosis title={t('common.OS')} diagnosisSet={record.leftEyeDiagnosis} />
                        </div>
                        <div>
                            <Diagnosis title={t('common.OD')} diagnosisSet={record.rightEyeDiagnosis} />
                        </div>
                    </div>
                },
                width: 200
            },
			{
				...getActionsColumn()
			}
        ];

	if (sort) {
		const sortedColumn = columns.filter(x => x.dataIndex === sort.column)[0];

		if (sortedColumn) {
			sortedColumn.sortOrder = sort.order;
		}
	}

	useEffect(() => {
		loadExams(
			pagination.current,
			sort.column,
			sort.order,
			searchParams);
	}, []);

	const handleResendClick = function(e, record) {
		setExamResendPending({[record.examinationId] : true});
		ExamApiService.resendExam(record.examinationId)
			.then(() => {
				loadExams(
					pagination.current,
					sort.column,
					sort.order,
					searchParams);
			})
			.finally(() => {
				setExamResendPending({[record.examinationId] : false});
			});

		e.stopPropagation();
		return false;
	}

	const loadExams = function (page, sortField, sortOrder, searchParams) {
		setIsLoading(true);

		if (sortOrder === undefined || sortField === undefined) {
			sortField = undefined;
			sortOrder = undefined;
		}

		return ExamApiService
			.getSSMUPaginatedExaminations(page, pagination.pageSize, sortField, sortOrder, searchParams)
			.then(
				exams => {
					setExams(exams.data);
					setIsLoading(false);
					
					dispatch(update({
						SGMU_GRID_FEATURE: {
							current: page,
							pageSize: pagination.pageSize,
							total: exams.totalElements,
							position: 'both'
						}
					} ));

					dispatch(updateSort({
						SGMU_GRID_FEATURE: {
							column: sortField,
							order: sortOrder
						}
					}));
				},
				() => {
					setIsLoading(false);
				}
			);
	}

	const handleTableChange = (pagination, filters, sorter) => {
		loadExams(pagination.current, sorter.columnKey, sorter.order, searchParams)
	};

	const onRowClick = function(record) {
		history.push(`./sgviewer/${record.examinationId}`);
	}

	return (
		<Grid columns={columns} handleTableChange={handleTableChange} isLoading={isLoading}
			data={exams} pagination={pagination} onRowClick={onRowClick}></Grid>
	);
}