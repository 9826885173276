import React, { useState, useEffect } from "react";
import { Form, Layout, Input, Button, Select, notification, Icon, Checkbox } from 'antd';

import { PresetsService } from "../../../services/presetsService";
import { OrganizationsService } from "../../../services/organizationsService";
import { DepartmentsService } from "../../../services/departmentsService";
import { UsersService } from "../../../services/usersService";
import "./Users.scss";
import OrganizationSelect from '../../../components/OrzanizationSelect/OrganizationSelect';
import {
	useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreateUserPage(props) {
	const { getFieldDecorator } = props.form;

	let history = useHistory();
	const [roles, setRoles] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [selectedRole, setSelectedRole] = useState(undefined);
	const [isSavingInProgress, setSavingInProgress] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [departmentsLoading, setDepartmentsLoading] = useState(false);
	const [user, setUser] = useState({active: true});
	const {t} = useTranslation();

	const createUser = (e) => {
		e.preventDefault();

		if (!isOrganizationSelectValid()) {
			notification['warning']({ message: "Invalid organization select. Organization must have at least one department." });
			return;
		}

		props.form.validateFields((errors, values) => {
			if (!errors) {
				setSavingInProgress(true);

				UsersService.save({
					userName: values.userName, userPassword: values.userPassword,
					hidden: false, roleId: selectedRole, organizations: user.organizations, active: values.active
				})
					.then(() => { 
						history.push('/dashboard/admin/users');
						notification['success']({ message: t('admin.userCreated', {name: values.userName}) });
					 })
					.catch(() => { notification['warning']({ message: t('admin.failedToCreateUser')}) })
					.finally(() => setSavingInProgress(false));
			}
		});
	};

	const isOrganizationSelectValid = () => {
		if (!user.organizations)
			return true;

		return user.organizations.every((o) => {
			var orgDepartments = departments.filter(x => x.organizationId === o.organizationId);

			if (!orgDepartments || orgDepartments.length === 0)
				return false;

			if (!o.allDepartments && (!o.departments || o.departments.length === 0)) {
				return false;
			}

			return true;
		});
	}

	useEffect(() => {
		Promise.all([PresetsService.getVisibleRoles(), OrganizationsService.getAll()])
			.then(([roles, organizations]) => {
				setRoles(roles);
				setOrganizations(organizations.map(org => { org.departments = undefined; return org; }));

				props.form.setFieldsValue({
					role: selectedRole,
				});

				var departmentsRequest = organizations.map((org) => {
					return DepartmentsService.getAll(org.id);
				});

				setDepartmentsLoading(true);

				Promise.all(departmentsRequest)
					.then((deps) => {
						var allDepartments = [];

						deps.forEach((d) => {
							d.forEach((x) => {
								var linkedOrganization = organizations.filter((o) => {
									return o.id === x.organizationId ? o : null;
								})[0];

								x.organizationName = linkedOrganization ? linkedOrganization.name : '';

								allDepartments.push(x);
							});

						});

						setDepartments(allDepartments);
					})
					.finally(() => {
						setDepartmentsLoading(false);
					});
			})
			.finally(() => setIsLoading(false));
	}, []);

	const selectRole = (value) => {
		setSelectedRole(value);
	}

	const selectOrganization = (orgId, oldOrgID) => {
		var selectedOrganization = organizations.find(x => x.id === orgId);

		var fileteredOrganizations = user.organizations.map(
			x => {
				if (x.organizationId === oldOrgID) {
					return {
						organizationId: selectedOrganization.id,
						allDepartments: false,
					};
				}

				return x;
			});


		setUser({ ...user, organizations: fileteredOrganizations });
	}

	const selectDepartment = (selectedDeptIds, orgId) => {
		var selectedOrganization = organizations.find(x => x.id === orgId);

		var selectedDepartmentsPerOrganization = [];

		selectedDeptIds.forEach(dep => {
			var d = departments.find(x => x.id === dep && x.organizationId === orgId);
			selectedDepartmentsPerOrganization.push(d);
		});

		selectedOrganization.departments = selectedDepartmentsPerOrganization;


		var usr = { ...user };
		if (user.organizations.find(x => x.organizationId === orgId)) {
			var selectedOrg = usr.organizations.find(x => x.organizationId === orgId);
			selectedOrg.departments = selectedOrganization.departments;
		} else {
			usr.organizations.push(selectedOrganization);
		}

		setUser(usr);
	};

	const removeOrganization = (orgId) => {
		var usr = { ...user };

		usr.organizations = usr.organizations.filter(x => x.organizationId !== orgId);

		setUser(usr);
	}

	const checkAllDepartments = (e, orgId) => {
		var usr = { ...user };

		usr.organizations = usr.organizations.map(x => {
			if (x.organizationId === orgId) {
				x.allDepartments = e.target.checked;
			}

			return x;
		});

		setUser(usr);
	}

	const addOrganization = () => {
		var usr = { ...user };

		if (!usr.organizations) {
			usr.organizations = [];
		}

		usr.organizations.push({});

		setUser(usr);
	}

	const renderOrzanizations = () => {
		const filteredOrgs = organizations.filter(x => !(user.organizations && user.organizations.some(y => y.organizationId === x.id)));

		return (user.organizations && user.organizations.map(o => {
			const selectedOrganization = organizations.filter(x => x.id === o.organizationId)[0] || {};
			selectedOrganization.allDepartments = o.allDepartments;

			return (<div key={o.organizationId} className="user-organization-block">
				<OrganizationSelect
					organizations={filteredOrgs}
					departments={departments}
					selectDepartment={selectDepartment}
					selectOrganization={selectOrganization}
					onAllDepartmentsCheck={checkAllDepartments}
					onOrganizationRemove={removeOrganization}
					selectedOrganization={selectedOrganization}
					selectedDepartments={o.departments ? o.departments.map(x => x.id) : []}>
				</OrganizationSelect>
			</div>);
		}));
	}

	return (
		<Layout>
			{isLoading ? <div>{t('common.loading')}</div> : <div>
				<h1>
					{t('admin.createUser')}
				</h1>
				<div className="user-form-container">
					<Form onSubmit={createUser}>
						<div>
							<Form.Item label={t('common.username')}>
								{getFieldDecorator('userName', {
									rules: [{ required: true, message: t('admin.userNameInvalid') }],
								})(
									<Input />,
								)}
							</Form.Item>
							<Form.Item label={t('common.password')}>
								{getFieldDecorator('userPassword', {
									rules: [{ required: true, message: t('admin.passwordInvalid') }],
								})(
									<Input.Password />,
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator('active', {
									rules: [],
									initialValue: user.active,
									valuePropName: 'checked'
								})(
									<Checkbox>{t('common.active')}</Checkbox>,
								)}
							</Form.Item>
							<Form.Item label={t('common.role')}>
								{getFieldDecorator('role', {
									rules: [{ required: true, message: t('admin.roleInvalid') }],
								})(
									<Select
										onChange={(x) => selectRole(x)}
										style={{ width: 500 }}
									>
										{roles.map(item => (
											<Select.Option key={item.id} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>,
								)}
							</Form.Item>
							{
								departmentsLoading ? <div> <Icon type="loading" />{t('common.loading')}</div> : renderOrzanizations()
							}
						</div>
						<div className="d-flex justify-content-between mt-2">
							<div>
								<Button icon="plus" onClick={addOrganization}>
									{t('admin.addOrganization')}
								</Button>
							</div>
							<Button htmlType="submit" loading={isSavingInProgress}
								type="primary">{t('common.save')}</Button>
						</div>
					</Form>
				</div>
			</div>}

		</Layout>
	);
}

export default Form.create()(CreateUserPage);