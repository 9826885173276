import React, { useState } from "react";
import { Button, Icon, notification } from "antd";
import { useTranslation } from "react-i18next";

export function LogoutWidget (props) {
	const [isLoading, setLoading] = useState(false);
	const {t} = useTranslation();

	const handleClick = () => {
		setLoading(true);
		props.handleClick()
			.catch(() => notification.error({ message: t('logout.logoutError') }))
			.finally(() => setLoading(false));
	}

	return (
		<Button className="logout-btn" type="link" block onClick={handleClick}>
			{isLoading ? <Icon type="loading" /> :
				<span>
					{t('logout.logoutButton')}
				</span>}
		</Button>
	);
}
