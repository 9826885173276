import React, { Component } from "react";
import "./AppVersion.scss";
import { SupportApiService } from "../../api/SupportApi.service";

export default class AppVersion extends Component {
	state = { appVersion: "" };

	componentDidMount() {
		SupportApiService.getAppVersion()
			.then(value => {
				this.setState(
					{ appVersion: value }
				);
			});
	}

	render = () => (this.state.appVersion &&
				<div className="appVersion">v.{this.state.appVersion}</div>
		);
}
