import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Input, notification } from "antd";
import "./LoginPage.scss";
import "antd/dist/antd.css"
import { withRouter } from "react-router-dom";
import ManualIcon from "../../components/ManualIcon/ManualIcon";
import { AuthenticationService } from '../../services/authenticationService';
import { UserService } from "../../services/userService";
import { useDispatch } from "react-redux";
import detectBrowserLanguage from 'detect-browser-language'
import { useTranslation } from "react-i18next";
import { setUserFeatures, setUserCurrentOrganization, setUserOrganizations, setLogedInUser } from "../../actions/accountActions";

function LoginPage(props) {
	const { getFieldDecorator } = props.form;
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const browserLanguage = detectBrowserLanguage();
		i18n.changeLanguage(browserLanguage.toUpperCase());
	}, [])

	const onError = () => {
		notification.error({ message: "An error occurred during login" });
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		props.form.validateFields((err, values) => {
			if (!err) {
				setIsLoading(true);
				AuthenticationService.doLogin(values.userName, values.password)
					.then(() => {
						UserService.getDefaultOrganizationAndFeatures()
							.then((data) => {
								dispatch(setUserFeatures(data.features));
								if (data.organization) {
									dispatch(setUserCurrentOrganization(data.organization.id));
								}
								dispatch(setUserOrganizations(data.organizations));
								dispatch(setLogedInUser(true));
							})
							.catch(() => onError());
					})
					.catch(() => {
						onError();
					})
					.finally(() => setIsLoading(false));
			}
		});
	};

	return (
		<div className="login-form-container">
			<Form onSubmit={handleSubmit} className="login-form">
				<Form.Item label={t('login.username')}>
					{getFieldDecorator("userName", {
						rules: [{ required: true, message: t('login.usernameEmpty') }],
					})(
						<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
						/>
					)}
				</Form.Item>
				<Form.Item label={t('login.password')}>
					{getFieldDecorator("password", {
						rules: [{ required: true, message: t('login.passwordEmpty') }],
					})(
						<Input prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} type="password"
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						{isLoading ? <Icon type="loading" /> :
							<span>
								{t('login.submit')}
							</span>}
					</Button>
				</Form.Item>
			</Form>
			<ManualIcon />
			<p className="contact">{t('login.getAccess')}
				<a href="mailto:ocuscreen@enbisys.com"> ocuscreen@enbisys.com</a>
			</p>
		</div>
	)
}

export default withRouter(Form.create()(LoginPage));
