import React, { useState, useEffect } from "react";
import { Form, Select, notification, Layout, Input, Button } from 'antd';
import { DepartmentsService } from "../../../services/departmentsService";
import { OrganizationsService } from "../../../services/organizationsService";
import {
	useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditDepartmentPage(props) {
	let { id } = useParams();
	const { getFieldDecorator } = props.form;
	const [organizations, setOrganizations] = useState([]);
	const [department, setDepartment] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [isSavingInProgress, setIsSavingInProgress] = useState(false);
	const [organization, setOrganization] = useState(null);
	const {t} = useTranslation();

	useEffect(() => {
		Promise.all([OrganizationsService.getAll(), DepartmentsService.getById(id)])
		.then(([org, dep]) => {
			setOrganizations(org);
			setLoading(false);
			setDepartment(dep);

			props.form.setFieldsValue({
				name: dep.name,
				description: dep.description
			});

			setOrganization(dep.organizationId);
		})
		.finally(() => setLoading(false));

	}, [])

	const editDepartment = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors && organization) {
				setIsSavingInProgress(true);

				DepartmentsService.save({
					id: department.id,
					name: values.name,
					description: values.description, 
					organizationId: organization
				})
				.then(() => {
					notification['success']({ message: t('admin.departmentEditedMessage') })
				})
				.catch(() => {notification.error({ message: t('admin.failedToEditDepartment')})})
				.finally(() => {
					setIsSavingInProgress(false);
				});
			}
		});
	}

	return (
		<Layout>
			{isLoading ? <div>Loading...</div> : <div>
				<h1>
					{ t('admin.editDepartment')}
				</h1>
				<Form className="create-edit-form" onSubmit={editDepartment}>
					<div>
						<Form.Item label={t('admin.departmentName')}>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('admin.departmentNameInvalid') }],
							})(
								<Input disabled={props.isReadonly} />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.departmentDescription') }>
							{getFieldDecorator('description', {
								rules: [{ required: true, message: t('admin.departmentDescriptionInvalid') }],
							})(
								<Input disabled={props.isReadonly} />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('common.organization')}>
							<Select
								value={organization}
								disabled={props.isReadonly}
								onChange={(x) => setOrganization(x)}
								style={{ width: 500 }}
							>
								{organizations.map(item => (
									<Select.Option key={item.id} value={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div className="mt-2">
						<Button htmlType="submit" loading={isSavingInProgress}
							type="primary" disabled={props.isReadonly}>{t('common.save')}</Button>
					</div>
				</Form>
			</div>}
		</Layout>
	);
}

export default Form.create()(EditDepartmentPage);