export const SET_USER_FEATURES = "SET_USER_FEATURES";
export const SET_LOGED_IT_USER = "SET_LOGED_IT_USER";
export const SET_USER_CURRENT_ORGANIZATION = "SET_USER_CURRENT_ORGANIZATION";
export const SET_USER_ORGANIZATIONS = "SET_USER_ORGANIZATIONS";
export const CLEAN_USER_SETUP = "CLEAN_USER_SETUP";

export function setUserFeatures(features) {
    return {
        type: SET_USER_FEATURES,
        features,
    }
}

export function setLogedInUser(isLogedIn) {
    return {
        type: SET_LOGED_IT_USER,
        isLogedIn,
    }
}

export function setUserCurrentOrganization(organization) {
    return {
        type: SET_USER_CURRENT_ORGANIZATION,
        organization,
    }
}

export function setUserOrganizations(organizations) {
    return {
        type: SET_USER_ORGANIZATIONS,
        organizations,
    }
}
export function cleanUserSetup() {
    return {
        type: CLEAN_USER_SETUP
    }
}